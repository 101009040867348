import { SessionDataService } from './../../session-data.service';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(
    public authService: AuthService,
    public toastr: ToastrService,
    public router: Router,
    public sessionData: SessionDataService,
    public nav: NavbarService
  ) { }

  ngOnInit(): void {
    this.nav.hide();
  }
  Login(f: NgForm) {

    const loginObserver = {
      next: (x: any) => {
        setTimeout(() => {
            this.router.navigate(['/home']);
      });
      },
      error: (err: any) => {
        alert('Fail');
      },
    };

    this.authService.login(f.value).subscribe(loginObserver);
  }

}
