import { PrezentaFotovoltaiceComponent } from './components/prezenta/prezenta-fotovoltaice/prezenta-fotovoltaice.component';
import { GestiuneFotovoltaiceComponent } from './components/fotovoltaice/gestiune-fotovoltaice/gestiune-fotovoltaice.component';
import { FotovoltaiceProiecteComponent } from './components/fotovoltaice/fotovoltaice-proiecte/fotovoltaice-proiecte.component';
import { FotovoltaiceProduseComponent } from './components/fotovoltaice/fotovoltaice-produse/fotovoltaice-produse.component';
import { RapoartedelucruDashboardComponent } from './components/clienti/rapoartedelucru-dashboard/rapoartedelucru-dashboard.component';
import { OferteDashboardComponent } from './components/clienti/oferte-dashboard/oferte-dashboard.component';
import { FacturiDashboardComponent } from './components/clienti/facturi-dashboard/facturi-dashboard.component';
import { DiverseDashboardComponent } from './components/clienti/diverse-dashboard/diverse-dashboard.component';
import { ContracteDashboardComponent } from './components/clienti/contracte-dashboard/contracte-dashboard.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { GestiuneAprovizionareRezervariComponent } from './components/aprovizionare/gestiune-aprovizionare-rezervari/gestiune-aprovizionare-rezervari.component';
import { RegisterUserComponent } from './components/register-user/register-user.component';
import { ProiecteClientiComponent } from './components/proiecte-clienti/proiecte-clienti.component';
import { DashboardClientComponent } from './components/clienti/dashboard-client/dashboard-client.component';
import { DatePersonaleComponent } from './components/date-personale/date-personale.component';
import { SolicitaServiciileNoastreComponent } from './components/solicita-serviciile-noastre/solicita-serviciile-noastre.component';
import { ListaProduseDecomandatComponent } from './components/aprovizionare/lista-produse-decomandat/lista-produse-decomandat.component';
import { PrezentaTabloterieComponent } from './components/prezenta/prezenta-tabloterie/prezenta-tabloterie.component';
import { TabloterieTablouriComponent } from './components/tabloterie/tabloterie-tablouri/tabloterie-tablouri.component';
import { TabloterieProiecteComponent } from './components/tabloterie/tabloterie-proiecte/tabloterie-proiecte.component';
import { TabloterieProduseComponent } from './components/tabloterie/tabloterie-produse/tabloterie-produse.component';
import { GestiuneComandaComponent } from './components/aprovizionare/gestiune-comanda/gestiune-comanda.component';
import { GestiuneAprovizionareComponent } from './components/aprovizionare/gestiune-aprovizionare/gestiune-aprovizionare.component';
import { GestiuneAprovizionareComandaComponent } from './components/aprovizionare/gestiune-aprovizionare-comanda/gestiune-aprovizionare-comanda.component';
import { OfertareEditareOfertaComponent } from './components/ofertare/ofertare-editare-oferta/ofertare-editare-oferta.component';
import { OfertareGestiuneVizualizariComponent } from './components/ofertare/ofertare-gestiune-vizualizari/ofertare-gestiune-vizualizari.component';
import { OfertareGestiuneOferteComponent } from './components/ofertare/ofertare-gestiune-oferte/ofertare-gestiune-oferte.component';
import { OfertareGestiuneEchipamenteComponent } from './components/ofertare/ofertare-gestiune-echipamente/ofertare-gestiune-echipamente.component';
import { OfertareGestiuneDescrieriComponent } from './components/ofertare/ofertare-gestiune-descrieri/ofertare-gestiune-descrieri.component';
import { OfertareCreareOfertaComponent } from './components/ofertare/ofertare-creare-oferta/ofertare-creare-oferta.component';
import { ListaClientiComponent } from './components/clienti/lista-clienti/lista-clienti.component';
import { ArticoleManoperaComponent } from './components/ofertare/articole-manopera/articole-manopera.component';
import { DocumentatieComponent } from './components/proiecte/documentatie/documentatie.component';
import { ChatComponent } from './components/proiecte/chat/chat.component';
import { AddPrezentaBirouComponent } from './components/prezenta/add-prezenta-birou/add-prezenta-birou.component';
import { AddPrezentaAtelierComponent } from './components/prezenta/add-prezenta-atelier/add-prezenta-atelier.component';
import { TabelPrezentaComponent } from './components/prezenta/tabel-prezenta/tabel-prezenta.component';
import { ListaAngajatiComponent } from './components/proiecte/lista-angajati/lista-angajati.component';
import { ProiecteComponent } from './components/clienti/proiecte/proiecte.component';
import { ProductDetailComponent } from './components/materiale/product-detail/product-detail.component';
import { RezervareProiecteComponent } from './components/materiale/rezervare-proiecte/rezervare-proiecte.component';
import { GestiuneVanzariComponent } from './components/materiale/gestiune-vanzari/gestiune-vanzari.component';
import { GestiuneTransferComponent } from './components/materiale/gestiune-transfer/gestiune-transfer.component';
import { GestiuneProiecteComponent } from './components/materiale/gestiune-proiecte/gestiune-proiecte.component';
import { GestiunePrincipalaComponent } from './components/materiale/gestiune-principala/gestiune-principala.component';
import { GestiunePersonalaComponent } from './components/materiale/gestiune-personala/gestiune-personala.component';
import { GestiuneAngajatiComponent } from './components/materiale/gestiune-angajati/gestiune-angajati.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { AddPrezentaProiectComponent } from './components/prezenta/add-prezenta-proiect/add-prezenta-proiect.component';
import { OfertareGestiuneProduseComponent } from './components/ofertare/ofertare-gestiune-produse/ofertare-gestiune-produse.component';
import { AuthGuard } from './guard/auth-guard.service';
import { GestiuneTabloterieComponent } from './components/tabloterie/gestiune-tabloterie/gestiune-tabloterie.component';

const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard]},
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent},
  { path: 'register-user', component: RegisterUserComponent},
  { path: 'gestiune-angajati', component: GestiuneAngajatiComponent, canActivate: [AuthGuard]},
  { path: 'gestiune-personala', component: GestiunePersonalaComponent, canActivate: [AuthGuard]},
  { path: 'gestiune-principala', component: GestiunePrincipalaComponent, canActivate: [AuthGuard]},
  { path: 'gestiune-proiecte', component: GestiuneProiecteComponent, canActivate: [AuthGuard]},
  { path: 'gestiune-transfer', component: GestiuneTransferComponent, canActivate: [AuthGuard]},
  { path: 'gestiune-vanzari', component: GestiuneVanzariComponent, canActivate: [AuthGuard]},
  { path: 'rezervare-proiecte', component: RezervareProiecteComponent, canActivate: [AuthGuard]},
  { path: 'product-detail', component: ProductDetailComponent, canActivate: [AuthGuard]},
  { path: 'proiecte', component: ProiecteComponent, canActivate: [AuthGuard]},
  { path: 'lista-angajati', component: ListaAngajatiComponent, canActivate: [AuthGuard]},
  { path: 'tabel-prezenta', component: TabelPrezentaComponent, canActivate: [AuthGuard]},
  { path: 'add-prezenta-atelier', component: AddPrezentaAtelierComponent, canActivate: [AuthGuard]},
  { path: 'add-prezenta-birou', component: AddPrezentaBirouComponent, canActivate: [AuthGuard]},
  { path: 'add-prezenta-proiect', component: AddPrezentaProiectComponent, canActivate: [AuthGuard]},
  { path: 'ofertare-gestiune-produse', component: OfertareGestiuneProduseComponent, canActivate: [AuthGuard]},
  { path: 'chat', component: ChatComponent, canActivate: [AuthGuard]},
  { path: 'documentatie', component: DocumentatieComponent, canActivate: [AuthGuard]},
  { path: 'articole-manopera', component: ArticoleManoperaComponent, canActivate: [AuthGuard]},
  { path: 'lista-clienti', component: ListaClientiComponent, canActivate: [AuthGuard]},
  { path: 'ofertare-creare-oferta', component: OfertareCreareOfertaComponent, canActivate: [AuthGuard]},
  { path: 'ofertare-gestiune-descrieri', component: OfertareGestiuneDescrieriComponent, canActivate: [AuthGuard]},
  { path: 'ofertare-gestiune-echipamente', component: OfertareGestiuneEchipamenteComponent, canActivate: [AuthGuard]},
  { path: 'ofertare-gestiune-oferte', component: OfertareGestiuneOferteComponent, canActivate: [AuthGuard]},
  { path: 'ofertare-gestiune-vizualizari', component: OfertareGestiuneVizualizariComponent, canActivate: [AuthGuard]},
  { path: 'ofertare-editare-oferta', component: OfertareEditareOfertaComponent, canActivate: [AuthGuard]},
  { path: 'gestiune-aprovizionare', component: GestiuneAprovizionareComponent, canActivate: [AuthGuard]},
  { path: 'gestiune-aprovizionare-comanda', component: GestiuneAprovizionareComandaComponent, canActivate: [AuthGuard]},
  { path: 'gestiune-comanda', component: GestiuneComandaComponent, canActivate: [AuthGuard]},
  { path: 'gestiune-tabloterie', component: GestiuneTabloterieComponent, canActivate: [AuthGuard]},
  { path: 'tabloterie-produse', component: TabloterieProduseComponent, canActivate: [AuthGuard]},
  { path: 'tabloterie-proiecte', component: TabloterieProiecteComponent, canActivate: [AuthGuard]},
  { path: 'tabloterie-tablouri', component: TabloterieTablouriComponent, canActivate: [AuthGuard]},
  { path: 'tabloterie-prezenta', component: PrezentaTabloterieComponent, canActivate: [AuthGuard]},
  { path: 'lista-produse-decomandat', component: ListaProduseDecomandatComponent, canActivate: [AuthGuard]},
  { path: 'solicita-serviciile-noastre', component: SolicitaServiciileNoastreComponent, canActivate: [AuthGuard]},
  { path: 'date-personale', component: DatePersonaleComponent, canActivate: [AuthGuard]},
  { path: 'dashboard-client', component: DashboardClientComponent, canActivate: [AuthGuard]},
  { path: 'proiecte-clienti', component: ProiecteClientiComponent, canActivate: [AuthGuard]},
  { path: 'gestiune-aprovizionare-rezervari', component: GestiuneAprovizionareRezervariComponent, canActivate: [AuthGuard]},
  { path: 'reset-password', component: ResetPasswordComponent},
  { path: 'change-password/:email/:token', component: ChangePasswordComponent},
  { path: 'contracte-dashboard', component: ContracteDashboardComponent},
  { path: 'diverse-dashboard', component: DiverseDashboardComponent},
  { path: 'facturi-dashboard', component: FacturiDashboardComponent},
  { path: 'oferte-dashboard', component: OferteDashboardComponent},
  { path: 'rapoartedelucru-dashboard', component: RapoartedelucruDashboardComponent},
  { path: 'fotovoltaice-produse', component: FotovoltaiceProduseComponent},
  { path: 'fotovoltaice-proiecte', component: FotovoltaiceProiecteComponent},
  { path: 'gestiune-fotovoltaice', component: GestiuneFotovoltaiceComponent},
  { path: 'prezenta-fotovoltaice', component: PrezentaFotovoltaiceComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
