import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-prezenta-atelier',
  templateUrl: './add-prezenta-atelier.component.html',
  styleUrls: ['./add-prezenta-atelier.component.css']
})
export class AddPrezentaAtelierComponent implements OnInit {
  today: any;
  todayInput: any;
  yesterday: any;
  message: string | undefined;
  userPrezentaModel: any = {
    proiectId: '',
    data: '',
    oreLucrate: '',
    userId: ''
  }
  appUrl: string = environment.appUrl;
  proiectId: any;

  constructor(public datepipe: DatePipe, public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {

    this.sessionData.current_page_title = "ADAUGĂ PREZENȚĂ PROIECT"
    this.nav.show();
    this.todayInput = new Date;
    this.today = new Date;
    this.yesterday = new Date;
    this.yesterday.setDate(this.today.getDate() - 1);
  }

  onSubmit(fEditCategory:any){

    this.sessionData.proiecte.forEach((element: { denumireProiect: string; id: any; }) => {
      if(element.denumireProiect == "Atelier"){
        this.proiectId = element.id;
      }
    });
    this.userPrezentaModel = {
      proiectId: this.proiectId,
      data: new Date(fEditCategory.value.Data),
      oreLucrate: fEditCategory.value.OreLucrate,
      userId: this.sessionData.user.id
    }
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    return this.http.post(this.appUrl + 'api/UserPrezenta/', this.userPrezentaModel, header).subscribe(
      (response) => {    
        window.location.reload();
      },
      (error) => { 
        console.error('error caught in component')
        this.message = "Eroare"
      }
    );
  }

}
