import { DatePipe, formatDate } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-prezenta-proiect',
  templateUrl: './add-prezenta-proiect.component.html',
  styleUrls: ['./add-prezenta-proiect.component.css']
})
export class AddPrezentaProiectComponent implements OnInit {
  today: any;
  selectedfile: File | any;
  todayInput: any;
  yesterday: any;
  message: string | undefined;
  userPrezentaModel: any = {
    proiectId: '',
    data: '',
    oreLucrate: '',
    userId: '',
    serviciuId: '',
    detaliiProiect: '',
    imaginiPrezentaList: ''
  }
  appUrl: string = environment.appUrl;
  listFormData: any;
  urls: any = [];
  base64textString: any = {
    base64: '',
    name: ''
  }
  base64textStrings: any = [];
  pictureModel: any = {
    data:'',
    file:'',
    files:'',
    userId:'',
    userPrezentaId:''
  }
  picturesModel: any = [];
  constructor(public datepipe: DatePipe, public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {

    this.sessionData.current_page_title = "ADAUGĂ PREZENȚĂ PROIECT"
    this.nav.show();
    this.todayInput = new Date;
    this.today = new Date;
    this.yesterday = new Date;
    this.yesterday.setDate(this.today.getDate() - 1);
  }

  onSubmit(fEditCategory:any){

    let myDate = new Date(Date.now());
    this.base64textStrings.forEach((element: any) => {
      this.pictureModel = {
        data: myDate,
        file: element.name,
        files: element.base64,
        userId: this.sessionData.user.id,
        userPrezentaId: this.sessionData.user.id
      }
      this.picturesModel.push(this.pictureModel);
    });

    this.userPrezentaModel = {
      proiectId: Number(fEditCategory.value.DenumireProiect),
      data: new Date(fEditCategory.value.Data),
      oreLucrate: fEditCategory.value.OreLucrate,
      userId: this.sessionData.user.id,
      serviciuId: fEditCategory.value.DenumireServiciu,
      detaliiProiect: fEditCategory.value.Detalii,
      imaginiPrezentaList: this.picturesModel
    }
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    return this.http.post(this.appUrl + 'api/UserPrezenta/addPrezenta/',  this.userPrezentaModel, header).subscribe(
      (response) => {
        window.location.reload();
      },
      (error) => {
        console.error('error caught in component')
        this.message = "Eroare"
      }
    );
  }
  onFS(event: any){
    if(<File>event.target.files[0] != null){
      this.selectedfile = <File>event.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload=(a:any)=>{
        this.urls.push(a.target.result);
      }
    }
  }
  _handleReaderLoaded(readerEvt: any) {
    var binaryString = readerEvt.target.result;
    this.base64textString = {
      base64: btoa(binaryString),
      name: this.selectedfile.name
    }
    this.base64textStrings.push(this.base64textString);
   }
   //#region Adauga fisier
   addPictureModal(content: any) {
    this.modalService.open(content);
    this.message = "";
  }

  AddPictureResource(fAddPictureCategory: NgForm){
    var reader = new FileReader();

    reader.onload =this._handleReaderLoaded.bind(this);

    reader.readAsBinaryString(this.selectedfile);

    this.message = "Imaginea a fost adaugată!"

  }
  //#endregion

  //#region Retur fisier
    // returFileModal(content: any, gp: any) {
    //   this.modalService.open(content);
    //   this.fileId = gp.id
    // }
    // DeleteFileResource(fReturProdus: any){

    //   console.log(this.fileId);
    //   this.http.delete(this.appUrl + 'api/TabloterieFisiereTablou/' + this.fileId).subscribe(
    //     (response) => {
    //       this.message = "Fișierul a fost șters!"
    //     },
    //     (error) => {
    //       console.error('error caught in component')
    //       this.message = "Eroare"
    //     }
    //   );

    // }
    //#endregion
    refresh(){
      if(this.message == null){
        this.modalService.dismissAll();
      }
      else{
        window.location.reload();
      }
    }
    refreshImage(){
        this.modalService.dismissAll();
    }

    openImage(item: any){
      console.log(item);
      window.open(this.appUrl + "/assets/" + item.file);
    }

}
