import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-proiecte-clienti',
  templateUrl: './proiecte-clienti.component.html',
  styleUrls: ['./proiecte-clienti.component.css']
})
export class ProiecteClientiComponent implements OnInit {
  projectOption: any = "Activ";
  userId: number | undefined;
  appUrl: string = environment.appUrl;
  selectedfile: File | undefined;
  message: string | undefined;
  clientId: any;
  clientName: any;
  isCheckOferta: boolean = false;
  isCheckContracte: boolean = false;
  isCheckFacturi: boolean = false;
  isCheckRapoarte: boolean = false;
  isCheckDiverse: boolean = false;
  checkNext1: boolean = false;
  tipFisier: string = '';
 
  constructor(public datepipe: DatePipe, public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
    config.backdrop = 'static';
    config.keyboard = false;
    this.clientId = localStorage.getItem('ClientId');
    this.clientName = localStorage.getItem('ClientName');

  }
  ngOnInit(): void {
    this.sessionData.current_page_title = "PROIECTE";
  }

  selectArea(area: any){
    this.sessionData.clientProiectId = area.id;
    let item: any;
    this.sessionData.dashboardClientInventar.proiectList.forEach((element: any) => {
      if(element.id == area.id){
        item = element;
      }
    });
    this.sessionData.stareOferte = item.stareOferte;
    this.sessionData.stareContracte = item.stareContracte;
    this.sessionData.stareFacturi = item.stareFacturi;
    this.sessionData.stareRapoarteDeLucru = item.stareRapoarteDeLucru;
    this.sessionData.stareDiverse = item.stareDiverse;
  }
  refresh(){
    if(this.message == null){
      this.modalService.dismissAll();
    }
    else{
      window.location.reload();
    }
  }
  
  goToOferte(){
    localStorage.setItem('ProjectId', this.sessionData.clientProiectId);
    this.router.navigate(['/oferte-dashboard']);
  }
  goToContracte(){
    localStorage.setItem('ProjectId', this.sessionData.clientProiectId);
    this.router.navigate(['/contracte-dashboard']);
  }
  goToFacturi(){
    localStorage.setItem('ProjectId', this.sessionData.clientProiectId);
    this.router.navigate(['/facturi-dashboard']);
  }
  goToRapoarte(){
    localStorage.setItem('ProjectId', this.sessionData.clientProiectId);
    this.router.navigate(['/rapoartedelucru-dashboard']);
  }
  goToDiverse(){
    localStorage.setItem('ProjectId', this.sessionData.clientProiectId);
    this.router.navigate(['/diverse-dashboard']);
  }

}
