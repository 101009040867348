import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionDataService {

  public user:any;
  public categories: any;
  public users: any;
  public userResourseId: any;
  public userRole: any;
  public resurse: any;
  public current_page_title: any;
  public productId : any;
  public gestiunePrincipalaInventory : any;
  public productDetails: any;
  public proiecte: any;
  public gestiuneTransfer : any;
  public gestiunePersonala: any;
  public gestiuneProiect: any;
  public gestiuneVanzari: any;
  public gestiuneRezervareProiect: any;
  public roles: any;
  public userPrezenta: any;
  public ofertareGestiuneProduse: any;
  public totalStocksValue: any;
  public projectProfitability: any;
  public totalProjectsValue: any;
  public projectsByStatus: any;
  public criticStocks: any;
  public projectId: any;
  public projectDetails: any;
  public chat: any;
  public documentatie: any;

  public single: any;
  public single1: any;
  public single2: any;
  public single3: any;

  public projectDataSource: any = [];
  public resourceData : any = [];
  public eventSettings : any;
  public categoryDataSource : any = [];
  public resourceDataSource: any = [];

  public serviciiManopera: any = [];
  public clientId: any = [];
  public ofertareGestiuneDescriere: any = [];
  public ofertareGestiuneEchipamente: any = [];
  public ofertareGestiuneVizualizari: any = [];
  public inventarOfertare: any;
  public imageb64: any;

  public listaOferte: any;
  public ofertaId: any;

  public listaComenzi: any;
  public listaProduseComenzi: any;
  public comandId: any;
  public listaGestiuneComenzi: any;

  public gestiuneTabloterie: any;
  public tabloterieProiecte: any;
  public panelId: any;
  public listaTablouri: any;
  public listaProduseTablou: any;
  public prezentaTabloterie: any;
  public listaProduseComenziDeComandat: any;
  public listaProducatori: any;
  public proiecteTablouri: any;
  public dashboardClientInventar: any;

  public getGestiunePersonalaById: any;
  public clientProiectId: any;

  public showSubmenu: boolean = false;
  public showSubmenu1: boolean = false;
  public showSubmenu2: boolean = false;
  public showSubmenu3: boolean = false;
  public showSubmenu4: boolean = false;
  public showSubmenu5: boolean = false;
  public showSubmenu6: boolean = false;

  public stareOferte: any;
  public stareContracte: any; 
  public stareFacturi: any; 
  public stareRapoarteDeLucru: any; 
  public stareDiverse: any; 

  public listaGestiuneAprovizionareRezervari: any;
  constructor() { }
}
