import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-gestiune-principala',
  templateUrl: './gestiune-principala.component.html',
  styleUrls: ['./gestiune-principala.component.css']
})
export class GestiunePrincipalaComponent implements OnInit {

  //#region Declarari
  userId: number | undefined;
  appUrl: string = environment.appUrl;
  selectedfile: File | undefined;
  message: string | undefined;
  denumire: any;
  gPrincipala : any;
  p: number = 1;
  gPrincipalaModel: any = {
    id: '',
    codIntern: '',
    codExtern: '',
    denumire: '',
    producator: '',
    cantitate: '',
    cantitateReferinta: '',
    locatie: '',
    data: '',
    numeUser: '',
    critic: '',
    images: '',
    image: '',
    numarFacturaAchizitie: ''
  }
  addGTransferModel: any = {
    gestiunePrincipalaId: '',
    cantitate: '',
    userId: '',
    numeUser: ''
  };
  addRezervareModel: any = {
    gestiunePrincipalaId: '',
    cantitate: '',
    proiectId: '',
    numeUser: ''
  };
  addGVanzareModel: any = {
    gestiunePrincipalaId: '',
    cantitate: '',
    numeUser: '',
    mentiuni: ''
  };
  fileName= 'ExcelSheet.xlsx';
  userName : any;
  file : any = 'file';
  buffer: any;

//#endregion

  constructor(public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
    // var header = {
    //   headers: new HttpHeaders()
    //     .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    // }
    this.sessionData.current_page_title = "GESTIUNE PRINCIPALA"
    // this.http.get<any>(this.appUrl + 'api/GestiunePrincipala/').subscribe((data) => {
    //   this.gPrincipala = data.sort((a: { denumire: number; }, b: { denumire: number; }) => (a.denumire < b.denumire ? -1 : 1));
    //   console.log(data);
    // });
  }

  //functie pentru fisierul incarcat
  onFS(event: any){
    if(<File>event.target.files[0] != null){
      this.selectedfile = <File>event.target.files[0];
    }
  }

//#region Adauga produs
addModal(content: any) {
  this.modalService.open(content);
}

AddResource(fAddCategory: NgForm){

  let myDate = new Date(Date.now());
  fAddCategory.value.File = "file";
  fAddCategory.value.Files = this.selectedfile;
  const formData = new FormData();
  formData.append("CodIntern", fAddCategory.value.CodIntern.toString());
  formData.append("CodExtern", fAddCategory.value.CodExtern.toString());
  formData.append("Denumire", fAddCategory.value.Denumire.toString());
  formData.append("Producator", fAddCategory.value.Producator.toString());
  formData.append("Cantitate", fAddCategory.value.Cantitate);
  formData.append("Data", myDate.toDateString());
  formData.append("NumeUser", (this.sessionData.user.nume + " " + this.sessionData.user.prenume).toString());
  formData.append("CantitateReferinta", fAddCategory.value.CantitateReferinta);
  formData.append("Locatie", fAddCategory.value.Locatie.toString());
  if(fAddCategory.value.Critic == true){
    formData.append("Critic", "true");
  }
  else{
    formData.append("Critic", "false");
  }
  formData.append("NumarFacturaAchizitie", fAddCategory.value.NumarFacturaAchizitie.toString());
  formData.append("Image", fAddCategory.value.File.toString());
  formData.append("Images",  fAddCategory.value.Files);

  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  return this.http.post(this.appUrl + 'api/gestiunePrincipala/', formData, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );

}
//#endregion

//#region Editare produs

  editModal(content: any, gp: any) {
    this.modalService.open(content);
    this.gPrincipalaModel.id = gp.id;
    this.gPrincipalaModel.denumire = gp.denumire;
    this.gPrincipalaModel.images = gp.images;
    this.gPrincipalaModel.image = gp.image;
    this.gPrincipalaModel.codIntern = gp.codIntern;
    this.gPrincipalaModel.codExtern = gp.codExtern;
    this.gPrincipalaModel.producator = gp.producator;
    this.gPrincipalaModel.cantitate = gp.cantitate;
    this.gPrincipalaModel.cantitateReferinta = gp.cantitateReferinta;
    this.gPrincipalaModel.locatie = gp.locatie;
    this.gPrincipalaModel.critic = gp.critic;
    this.gPrincipalaModel.data = gp.data;
    this.gPrincipalaModel.numeUser = gp.numeUser;
    this.gPrincipalaModel.numarFacturaAchizitie = gp.numarFacturaAchizitie;
  }

  EditResource(fEditCategory: NgForm){
    fEditCategory.value.File = "file";
    const formData1 = new FormData();
    if(fEditCategory.value.Files != ""){
      fEditCategory.value.Files = this.selectedfile;
      formData1.append("Id", this.gPrincipalaModel.id);
      formData1.append("CodIntern", fEditCategory.value.CodIntern.toString());
      formData1.append("CodExtern", fEditCategory.value.CodExtern.toString());
      formData1.append("Denumire", fEditCategory.value.Denumire.toString());
      formData1.append("Producator", fEditCategory.value.Producator.toString());
      formData1.append("Cantitate", fEditCategory.value.Cantitate);
      formData1.append("Data", this.gPrincipalaModel.data);
      formData1.append("NumeUser", this.gPrincipalaModel.numeUser);
      formData1.append("CantitateReferinta", fEditCategory.value.CantitateReferinta);
      formData1.append("Locatie", fEditCategory.value.Locatie.toString());
      if(fEditCategory.value.Critic == true){
        formData1.append("Critic", "true");
      }
      else{
        formData1.append("Critic", "false");
      }
     
      formData1.append("NumarFacturaAchizitie", fEditCategory.value.NumarFacturaAchizitie.toString());
      formData1.append("Image", fEditCategory.value.File.toString());
      formData1.append("Images",  fEditCategory.value.Files);
    }
    else{
      formData1.append("Id", this.gPrincipalaModel.id);
      formData1.append("CodIntern", fEditCategory.value.CodIntern.toString());
      formData1.append("CodExtern", fEditCategory.value.CodExtern.toString());
      formData1.append("Denumire", fEditCategory.value.Denumire.toString());
      formData1.append("Producator", fEditCategory.value.Producator.toString());
      formData1.append("Data", this.gPrincipalaModel.data);
      formData1.append("NumeUser", this.gPrincipalaModel.numeUser);
      formData1.append("Cantitate", fEditCategory.value.Cantitate);
      formData1.append("CantitateReferinta", fEditCategory.value.CantitateReferinta);
      formData1.append("Locatie", fEditCategory.value.Locatie.toString());
      if(fEditCategory.value.Critic == true){
        formData1.append("Critic", "true");
      }
      else{
        formData1.append("Critic", "false");
      }
      formData1.append("NumarFacturaAchizitie", fEditCategory.value.NumarFacturaAchizitie.toString());
      formData1.append("Image", this.gPrincipalaModel.image);
    }
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }

    return this.http.put(this.appUrl + 'api/GestiunePrincipala/' + this.gPrincipalaModel.id, formData1, header).subscribe(
      (response) => {    
        // this.message = "Produsul a fost editat!"
        window.location.reload();
      },
      (error) => { 
        console.error('error caught in component')
        this.message = "Eroare"
      }
    );
  }

//#endregion

//#region Adauga Cantitate

addQuantityModal(content: any, gp: any) {
  this.modalService.open(content);
  this.gPrincipalaModel.id = gp.id;
  this.gPrincipalaModel.denumire = gp.denumire;
  this.gPrincipalaModel.images = gp.images;
  this.gPrincipalaModel.image = gp.image;
  this.gPrincipalaModel.codIntern = gp.codIntern;
  this.gPrincipalaModel.codExtern = gp.codExtern;
  this.gPrincipalaModel.producator = gp.producator;
  this.gPrincipalaModel.cantitate = gp.cantitate;
  this.gPrincipalaModel.cantitateReferinta = gp.cantitateReferinta;
  this.gPrincipalaModel.locatie = gp.locatie;
  this.gPrincipalaModel.critic = gp.critic;
  this.gPrincipalaModel.numarFacturaAchizitie = gp.numarFacturaAchizitie;
}

AddQuantityResource(fEditCategory: NgForm){

  fEditCategory.value.File = "file";
  const formData1 = new FormData();

  if(fEditCategory.value.Cantitate < 0){
    this.message = "Valoarea introdusa nu poate sa fie mai mica decat 0";
    return;
  }
  else{
    let sum = parseFloat(this.gPrincipalaModel.cantitate) + parseFloat(fEditCategory.value.Cantitate);
    formData1.append("Id", this.gPrincipalaModel.id);
    formData1.append("CodIntern", this.gPrincipalaModel.codIntern);
    formData1.append("CodExtern", this.gPrincipalaModel.codExtern);
    formData1.append("Denumire", this.gPrincipalaModel.denumire);
    formData1.append("Producator", this.gPrincipalaModel.producator);
    formData1.append("Cantitate", sum.toString());
    formData1.append("CantitateReferinta", this.gPrincipalaModel.cantitateReferinta);
    formData1.append("Locatie", this.gPrincipalaModel.locatie);
    formData1.append("Critic", this.gPrincipalaModel.critic);
    formData1.append("NumarFacturaAchizitie", fEditCategory.value.NumarFacturaAchizitie);
    formData1.append("Image", this.gPrincipalaModel.image);
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }

  return this.http.put(this.appUrl + 'api/GestiunePrincipala/' + this.gPrincipalaModel.id, formData1, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
  }


}

//#endregion

//#region Stergere Produs
  deleteModal(content: any, category: any) {
    this.modalService.open(content);
    this.gPrincipalaModel.id = category.id;
  }
  delete(event: any){
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    this.http.delete(this.appUrl + 'api/GestiunePrincipala/' + this.gPrincipalaModel.id, header).subscribe(
      (response) => {    
        window.location.reload();
      },
      (error) => { 
        console.error('error caught in component')
        this.message = "Eroare"
      }
    );
  }
//#endregion

//#region Transfera in Gestiune Transfer

addToGTransferModal(content: any, gp: any) {
  this.modalService.open(content);
  this.gPrincipalaModel.id = gp.id;
  this.gPrincipalaModel.denumire = gp.denumire;
  this.gPrincipalaModel.images = gp.images;
  this.gPrincipalaModel.image = gp.image;
  this.gPrincipalaModel.codIntern = gp.codIntern;
  this.gPrincipalaModel.codExtern = gp.codExtern;
  this.gPrincipalaModel.producator = gp.producator;
  this.gPrincipalaModel.cantitate = gp.cantitate;
  this.gPrincipalaModel.cantitateReferinta = gp.cantitateReferinta;
  this.gPrincipalaModel.locatie = gp.locatie;
  this.gPrincipalaModel.critic = gp.critic;
  this.gPrincipalaModel.numarFacturaAchizitie = gp.numarFacturaAchizitie;
}

AddGTransferResource(fAddGTransfer: NgForm){

  if(fAddGTransfer.value.Cantitate < 0){
    this.message = "Valoarea introdusa nu poate sa fie mai mica decat 0";
    return;
  }
  else if(fAddGTransfer.value.Cantitate > this.gPrincipalaModel.cantitate){
    this.message = "Valoarea introdusa este prea mare";
    return;
  }
  else{

    this.addGTransferModel = {
      gestiunePrincipalaId: this.gPrincipalaModel.id,
      cantitate: fAddGTransfer.value.Cantitate,
      userId: fAddGTransfer.value.UserName,
      numeUser: this.sessionData.user.nume + " " + this.sessionData.user.prenume

    }
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }

  return this.http.post(this.appUrl + 'api/GestiunePrincipala/addGestiuneTransfer', this.addGTransferModel, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
  }


}

//#endregion

//#region Transfera in Rezervare pe Proiect

addToRezervareModal(content: any, gp: any) {
  this.modalService.open(content);
  this.gPrincipalaModel.id = gp.id;
  this.gPrincipalaModel.denumire = gp.denumire;
  this.gPrincipalaModel.images = gp.images;
  this.gPrincipalaModel.image = gp.image;
  this.gPrincipalaModel.codIntern = gp.codIntern;
  this.gPrincipalaModel.codExtern = gp.codExtern;
  this.gPrincipalaModel.producator = gp.producator;
  this.gPrincipalaModel.cantitate = gp.cantitate;
  this.gPrincipalaModel.cantitateReferinta = gp.cantitateReferinta;
  this.gPrincipalaModel.locatie = gp.locatie;
  this.gPrincipalaModel.critic = gp.critic;
  this.gPrincipalaModel.numarFacturaAchizitie = gp.numarFacturaAchizitie;
}

AddRezervareResource(fAddGTransfer: NgForm){

  if(fAddGTransfer.value.Cantitate < 0){
    this.message = "Valoarea introdusa nu poate sa fie mai mica decat 0";
    return;
  }
  else if(fAddGTransfer.value.Cantitate > this.gPrincipalaModel.cantitate){
    this.message = "Valoarea introdusa este prea mare";
    return;
  }
  else{

    // this.sessionData.users.forEach((element: { id: any; nume: any; prenume: any; }) => {
    //   if(element.id == fAddGTransfer.value.UserName){
    //     this.userName = element.nume + " " + element.prenume;
    //   }
    // });

    this.addRezervareModel = {
      gestiunePrincipalaId: this.gPrincipalaModel.id,
      cantitate: fAddGTransfer.value.Cantitate,
      proiectId: fAddGTransfer.value.Proiect,
      numeUser: this.sessionData.user.nume + " " + this.sessionData.user.prenume
    }
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }

  return this.http.post(this.appUrl + 'api/GestiunePrincipala/addGestiuneRezervare', this.addRezervareModel, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
  }


}

//#endregion

//#region Transfera in Gestiune Vanzari

addToGVanzareModal(content: any, gp: any) {
  this.modalService.open(content);
  this.gPrincipalaModel.id = gp.id;
  this.gPrincipalaModel.denumire = gp.denumire;
  this.gPrincipalaModel.images = gp.images;
  this.gPrincipalaModel.image = gp.image;
  this.gPrincipalaModel.codIntern = gp.codIntern;
  this.gPrincipalaModel.codExtern = gp.codExtern;
  this.gPrincipalaModel.producator = gp.producator;
  this.gPrincipalaModel.cantitate = gp.cantitate;
  this.gPrincipalaModel.cantitateReferinta = gp.cantitateReferinta;
  this.gPrincipalaModel.locatie = gp.locatie;
  this.gPrincipalaModel.critic = gp.critic;
  this.gPrincipalaModel.numarFacturaAchizitie = gp.numarFacturaAchizitie;
}

AddGVanzareResource(fAddGTransfer: NgForm){



  if(fAddGTransfer.value.Cantitate < 0){
    this.message = "Valoarea introdusa nu poate sa fie mai mica decat 0";
    return;
  }
  else if(fAddGTransfer.value.Cantitate > this.gPrincipalaModel.cantitate){
    this.message = "Valoarea introdusa este prea mare";
    return;
  }
  else{

    this.sessionData.users.forEach((element: { id: any; nume: any; prenume: any; }) => {
      if(element.id == fAddGTransfer.value.UserName){
        this.userName = element.nume + " " + element.prenume;
      }
    });

    this.addGVanzareModel = {
      gestiunePrincipalaId: this.gPrincipalaModel.id,
      cantitate: fAddGTransfer.value.Cantitate,
      numeUser: this.sessionData.user.nume + " " + this.sessionData.user.prenume,
      mentiuni: fAddGTransfer.value.Mentiuni,
    }
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
  return this.http.post(this.appUrl + 'api/GestiunePrincipala/addGestiuneVanzare', this.addGVanzareModel, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
  }


}

//#endregion


Search(){
  
  if(this.denumire != ""){
    this.sessionData.gestiunePrincipalaInventory = this.sessionData.gestiunePrincipalaInventory.filter((res: { denumire: string; codIntern: string; }) => {
      return res.denumire.toLocaleLowerCase().match(this.denumire.toLocaleLowerCase()) || res.codIntern.toLocaleLowerCase().match(this.denumire.toLocaleLowerCase());
    });
  }else if(this.denumire == ""){
    this.authService.getGestiunePrincipala(this.authService.getToken());
  }

}

goToDetails(product: any){
  this.sessionData.productId = product.id;
  localStorage.setItem('ProductId',product.id);
  localStorage.setItem('ProductName',product.denumire);
  this.sessionData.gestiunePrincipalaInventory.forEach((element: { id: any; }) => {
    if(element.id == product.id){
      this.sessionData.productDetails = element;
    }
  });
  this.router.navigate(['/product-detail']);
}

//generare excel
exportExcel() : void {

  let url = this.appUrl + 'api/GestiunePrincipala/RaportGestiunePrincipala';
  window.open(url);
}

refresh(){
  if(this.message == null){
    this.modalService.dismissAll();
  }
  else{
    window.location.reload();
  }
}

//#region Adauga Csv
addCsvModal(content: any) {
  this.modalService.open(content);
}

AddCsvResource(fAddCsvCategory: NgForm){

  let myDate = new Date(Date.now());
  fAddCsvCategory.value.File = "file";
  fAddCsvCategory.value.Files = this.selectedfile;
  const formData = new FormData();
  this.buffer = true;

  formData.append("CsvFile",  fAddCsvCategory.value.Files);

  return this.http.post(this.appUrl + 'api/GestiunePrincipala/uploadCsv',  formData).subscribe(
  (response) => {    
    this.buffer = false
    this.message = "Fisierul a fost incarcat!"
  },
  (error) => { 
    this.buffer = false;
    this.message = "Eroare"
  }
);

}
//#endregion

}

