import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-gestiune-proiecte',
  templateUrl: './gestiune-proiecte.component.html',
  styleUrls: ['./gestiune-proiecte.component.css']
})
export class GestiuneProiecteComponent implements OnInit {

   //#region Declarari
   userId: number | undefined;
   appUrl: string = environment.appUrl;
   selectedfile: File | undefined;
   message: string | undefined;
   numeUser: any;
   projectOption: any = "Caută după Proiect";
   gPrincipala : any;
   p: number = 1;
   gProiectModel: any = {
     id: '',
     cantitate: '',
     data: '',
     gestiunePersonalaId: '',
     gestiunePrincipala: '',
     proiect: '',
     user: ''
   }
   returGPersonalaModel: any = {
     gestiunePersonalaId: '',
     gestiuneProiectId: '',
     numeUser: '',
     cantitate: ''
   };

   excelProiectModel: any = {
    proiectId: '',
    dataInceput: '',
    dataSfarsit: ''
   }

   excelProdusModel: any = {
    numeSauCod: '',
    alegere: '',
    dataInceput: '',
    dataSfarsit: ''
   }

   excelAngajatModel: any = {
    userId: '',
    dataInceput: '',
    dataSfarsit: ''
   }

   fileName= 'ExcelSheet.xlsx';
   userName : any;
   isChecked: any = false;
   isChecked1: any = false;
   proiectId: any;
   excelUserId: any;
 //#endregion

   constructor(public datepipe: DatePipe, public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
     config.backdrop = 'static';
     config.keyboard = false;
    }

    ngOnInit(): void {

     this.sessionData.current_page_title = "GESTIUNE PROIECTE"
     this.nav.show();
     var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
     this.http.get<any>(this.appUrl + 'api/GestiuneProiect/getGestiuneProiect', header).subscribe((data) => {
       this.sessionData.gestiuneProiect = data.sort((a: { data: Date; }, b: { data: Date; }) => (a.data > b.data ? -1 : 1));
     });

   }


 //#region Retur Produs
   returModal(content: any, gp: any) {
     this.modalService.open(content);
     this.gProiectModel = {
       id: gp.id,
       cantitate: gp.cantitate,
       data: gp.data,
       gestiunePersonalaId: gp.gestiunePersonalaId,
       gestiunePrincipala: gp.gestiunePrincipala,
       proiect: gp.proiect,
       user: gp.user
     }
   }
   DeleteResource(fReturProdus: any){


       this.returGPersonalaModel = {
        gestiunePersonalaId: this.gProiectModel.gestiunePersonalaId,
        gestiuneProiectId: this.gProiectModel.id,
         numeUser: this.gProiectModel.user.nume + " " + this.gProiectModel.user.prenume,
         cantitate: fReturProdus.value.Cantitate
       }
       var header = {
        headers: new HttpHeaders()
          .set('Authorization',  `Bearer ${this.authService.getToken()}`)
      }
       this.http.post(this.appUrl + 'api/GestiuneProiect/deleteToGestiunePersonala/' + this.gProiectModel.id,  this.returGPersonalaModel, header).subscribe(
        (response) => {    
          window.location.reload();
        },
        (error) => { 
          console.error('error caught in component')
          this.message = "Eroare"
        }
       );

   }
 //#endregion

 Search(){
   if(this.numeUser != ""){
     this.sessionData.gestiuneTransfer = this.sessionData.gestiuneTransfer.filter((res: { numeUser: string; }) => {
       return res.numeUser.toLocaleLowerCase().match(this.numeUser.toLocaleLowerCase());
     });
   }else if(this.numeUser == ""){
     this.ngOnInit();
   }

 }

 //enable proiecte
 checkValue(event: any){
 }
 selectChangeHandler (event: any) {
  if(event.target.value == "Toate"){
    this.projectOption = "Caută după Proiect";
  }
  this.projectOption = event.target.value;
}


//#region excel proiect
exportExcelProiect(content: any) : void {
  this.modalService.open(content);
}

ExportExcelProiectResource(fexportExcelProiect: any){
  if(fexportExcelProiect.value.gestiunePrincipala == true && fexportExcelProiect.value.rezervareProiect == true){
    this.message = "Alege o singura optiune!"
  }

  else if(fexportExcelProiect.value.gestiunePrincipala == true){
    this.excelProiectModel = {
      proiectId: 0,
      dataInceput: this.adjustDateForTimeOffset(fexportExcelProiect.value.DataInceput),
      dataSfarsit: this.adjustDateForTimeOffset(fexportExcelProiect.value.DataSfarsit)
    }

  }
  else if(fexportExcelProiect.value.rezervareProiect == true){

    this.sessionData.gestiuneProiect.forEach((element: { proiect: { denumireProiect: any; id: any; }; }) => {
      if(element.proiect.denumireProiect == fexportExcelProiect.value.Proiect){
        this.proiectId = element.proiect.id;
      }
    });
    this.excelProiectModel = {
      proiectId: this.proiectId,
      dataInceput: this.adjustDateForTimeOffset(fexportExcelProiect.value.DataInceput),
      dataSfarsit: this.adjustDateForTimeOffset(fexportExcelProiect.value.DataSfarsit)
    }
  }
  
  this.http.post(this.appUrl + 'api/GestiuneProiect/raportGestiuneProiectDupaProiect', this.excelProiectModel, { responseType: 'blob'} ).subscribe((data) => {
    const blob = new Blob([data], { type: 'application/octet-stream'});
    const url= window.URL.createObjectURL(blob);
    window.open(url);
  });

}
//#endregion

//#region excel produs
exportExcelProdus(content: any) : void {
  this.sessionData.gestiunePrincipalaInventory = this.sessionData.gestiunePrincipalaInventory.sort((a: { denumire: Date; }, b: { denumire: Date; }) => (a.denumire < b.denumire ? -1 : 1));
  this.modalService.open(content);
  console.log(this.sessionData.gestiunePrincipalaInventory);
}

ExportExcelProdusResource(fexportExcelProdus: any){
  if(fexportExcelProdus.value.alegereCod == true && fexportExcelProdus.value.alegereDenumire == true){
    this.message = "Alege o singura optiune!"
  }

  else if(fexportExcelProdus.value.alegereCod == true){
    this.excelProdusModel = {
      numeSauCod: fexportExcelProdus.value.CodInternProdus,
      alegere: 'DupaCod',
      dataInceput: this.adjustDateForTimeOffset(fexportExcelProdus.value.DataInceput),
      dataSfarsit: this.adjustDateForTimeOffset(fexportExcelProdus.value.DataSfarsit)
    }

  }
  else if(fexportExcelProdus.value.alegereDenumire == true){

    this.sessionData.gestiuneProiect.forEach((element: { proiect: { denumireProiect: any; id: any; }; }) => {
      if(element.proiect.denumireProiect == fexportExcelProdus.value.Proiect){
        this.proiectId = element.proiect.id;
      }
    });
    this.excelProdusModel = {
      numeSauCod: fexportExcelProdus.value.Denumire,
      alegere: 'DupaDenumire',
      dataInceput: this.adjustDateForTimeOffset(fexportExcelProdus.value.DataInceput),
      dataSfarsit: this.adjustDateForTimeOffset(fexportExcelProdus.value.DataSfarsit)
    }
  }
  this.http.post(this.appUrl + 'api/GestiuneProiect/raportGestiuneProiectDupaProdus', this.excelProdusModel, { responseType: 'blob'} ).subscribe((data) => {
    const blob = new Blob([data], { type: 'application/octet-stream'});
    const url= window.URL.createObjectURL(blob);
    window.open(url);
  });
}
//#endregion

//#region excel angajat
exportExcelAngajat(content: any) : void {

  this.modalService.open(content);
}

ExportExcelAngajatResource(fexportExcelAngajat: any){
  this.sessionData.users.forEach((element: { nume: string; prenume: string; id: any; }) => {
    if(element.nume + " " + element.prenume == fexportExcelAngajat.value.User){
      this.excelUserId = element.id;
    }
  });
  this.excelAngajatModel = {
    userId: this.excelUserId,
    dataInceput: this.adjustDateForTimeOffset(fexportExcelAngajat.value.DataInceput),
    dataSfarsit: this.adjustDateForTimeOffset(fexportExcelAngajat.value.DataSfarsit)
  }

  this.http.post(this.appUrl + 'api/GestiuneProiect/raportGestiuneProiectDupaAngajat', this.excelAngajatModel, { responseType: 'blob'} ).subscribe((data) => {
    const blob = new Blob([data], { type: 'application/octet-stream'});
    const url= window.URL.createObjectURL(blob);
    window.open(url);
  });
}
//#endregion

adjustDateForTimeOffset(dateToAdjust: any) {
  var offsetMs = dateToAdjust.getTimezoneOffset() * 60000;
  return new Date(dateToAdjust.getTime() - offsetMs);
  }

refresh(){
  if(this.message == null){
    this.modalService.dismissAll();
  }
  else{
    window.location.reload();
  }
}
}
