import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tabloterie-proiecte',
  templateUrl: './tabloterie-proiecte.component.html',
  styleUrls: ['./tabloterie-proiecte.component.css']
})
export class TabloterieProiecteComponent implements OnInit {
  userId: number | undefined;
  appUrl: string = environment.appUrl;
  selectedfile: File | undefined;
  message: string | undefined;
  numeUser: any;
  projectOption: any = "Caută după Proiect";
  gPrincipala : any;
  p: number = 1;
  proiectModel: any = {
    id: '',
    denumire: '',
    status: '',
    data: ''
  }

  proiectModelAdd: any = {
    denumire: '',
    status: '',
    data: ''
  }
  constructor(public datepipe: DatePipe, public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
    
   }

  ngOnInit(): void {
    this.sessionData.current_page_title = "PROIECTE TABLOTERIE"
    this.nav.show();
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    this.http.get<any>(this.appUrl + 'api/TabloterieProiect/', header).subscribe((data) => {
      this.sessionData.tabloterieProiecte = data.sort((a: { data: Date; }, b: { data: Date; }) => (a.data > b.data ? -1 : 1));
    });
  }

  goToPanels(comand: any){
    this.sessionData.panelId = comand.id;
    localStorage.setItem('ProjectId',comand.id);
    localStorage.setItem('ProjectName',comand.denumire);
    this.router.navigate(['/tabloterie-tablouri']);
  }

  //#region Editare
 editModal(content: any, gp: any) {
  this.modalService.open(content);
  this.proiectModel = {
    id: gp.id,
    denumire: gp.denumire,
    status: gp.status,
    data: gp.data
  }
}

EditResource(fEditCategory: NgForm){
  this.proiectModel.status = fEditCategory.value.Status;
  this.proiectModel.denumire = fEditCategory.value.Denumire
  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  return this.http.put(this.appUrl + 'api/TabloterieProiect/' + this.proiectModel.id, this.proiectModel, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
}
//#endregion

  //#region Stergere Proiect
returModal(content: any, category: any) {
  this.modalService.open(content);
  this.proiectModel.id = category.id;
}
delete(event: any){
  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  this.http.delete(this.appUrl + 'api/TabloterieProiect/' + this.proiectModel.id, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
}
//#endregion


  //#region Adauga Proiect
  addModal(content: any) {
    this.modalService.open(content);
  }

  AddResource(fAddCategory: NgForm){

    let myDate = new Date(Date.now());
    
    this.proiectModelAdd = {
      denumire: fAddCategory.value.DenumireProiect,
      status: fAddCategory.value.Status,
      data: myDate
    }
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    return this.http.post(this.appUrl + 'api/TabloterieProiect/', this.proiectModelAdd, header).subscribe(
      (response) => {    
        window.location.reload();
      },
      (error) => { 
        console.error('error caught in component')
        this.message = "Eroare"
      }
    );

  }
  //#endregion


  refresh(){
    if(this.message == null){
      this.modalService.dismissAll();
    }
    else{
      window.location.reload();
    }
  }
    
  selectChangeHandler(event: any) {
    //update the ui
    this.projectOption = event.target.value;
    if(this.projectOption == ''){
      this.projectOption = "Caută după Proiect";
    }
  }

}
