import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gestiune-fotovoltaice',
  templateUrl: './gestiune-fotovoltaice.component.html',
  styleUrls: ['./gestiune-fotovoltaice.component.css']
})
export class GestiuneFotovoltaiceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
