import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SessionDataService } from '../session-data.service';

@Injectable()
export class AuthGuard implements CanActivate {
  appUrl: string = environment.appUrl;
  token : any | undefined;
  refreshToken : any | undefined;
  role : any;

  constructor(private router: Router, private http: HttpClient, public sessionData: SessionDataService) {
  }
  async canActivate() {
    this.token = localStorage.getItem("Token");
    if (this.token && !this.tokenExpired(this.token)) {
      return true;
    }
    const isRefreshSuccess = await this.tryRefreshingTokens(this.token);

    if (!isRefreshSuccess) {
      this.router.navigate(["login"]);
    }

    return isRefreshSuccess;
  }

  private tokenExpired(token: string) {
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    return (Math.floor((new Date).getTime() / 1000)) >= expiry;
  }

  private async tryRefreshingTokens(token: string): Promise<boolean> {
    // Try refreshing tokens using refresh token
        this.refreshToken = localStorage.getItem("RefreshToken");

    if (!token || !this.refreshToken) {
      return false;
    }
    const credentials = JSON.stringify({ accessToken: token, refreshToken: this.refreshToken });
    let isRefreshSuccess: boolean;
    try {
      const response = await this.http.post(this.appUrl + 'api/token/refresh', credentials, {
        headers: new HttpHeaders({
          "Content-Type": "application/json"
        }),
        observe: 'response'
      }).toPromise();
      // If token refresh is successful, set new tokens in local storage.

      const newToken = (<any>response).body.accessToken;
      const newRefreshToken = (<any>response).body.refreshToken;
      localStorage.setItem("Token", newToken);
      localStorage.setItem("RefreshToken", newRefreshToken);
      isRefreshSuccess = true;
    }
    catch (ex) {
      isRefreshSuccess = false;
    }
    return isRefreshSuccess;
  }

}
