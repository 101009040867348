import { SessionDataService } from './../session-data.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { count, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import jwt_decode from "jwt-decode";
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  appUrl: string = environment.appUrl;
  //helper = new JwtHelperService();
  Nume: string | undefined;
  Prenume: string | undefined;
  Email: string | undefined;
  Id: number | undefined;
  token: any | undefined;
  flag : boolean = false;
  format = 'dd-MM-yyyy';
  locale = 'en-US';
  userRole: any;
  constructor(private sessionData: SessionDataService, private http: HttpClient) { }


  login(model: any){

    return this.http.post(this.appUrl + 'api/user/login', model).pipe(
      map((response: any) => {
        var decoded = jwt_decode(response.token) as any;
        //const decodedToken = this.helper.decodeToken(response.token);
        this.Nume = decoded.Nume;
        this.Prenume = decoded.Prenume;
        this.Email = decoded.Email;
        this.Id = decoded.Id;
        this.sessionData.user = this.Nume + " " + this.Prenume;
        localStorage.setItem('Token', response.token);
        localStorage.setItem('RefreshToken', response.refreshToken);
       // this.assignUserRole(decoded.Id);
        this.assignUserValues(decoded.Id);
        this.getTotalStocksValue();
        this.getProjectProfitability();
        this.getTotalValue();
        this.getProiecteByStatus();
        this.getCriticStocks();
        this.getGestiunePrincipala(response.token);
        this.getAllUsers();
        this.getOferte();
        this.getProiecte();
        this.getProiecteUser(decoded.Id);
        this.getTabloterieGestiuneTabloterie();
        this.getServiciiManopera();
        this.getListaProducatori();
        this.getGestiunePersonalaByUserId(this.Id);
        this.getProiecteByClientId(this.Id)
      })
    );
  }

 async loginByRefreshToken(model: any){

      await  this.http.post(this.appUrl + 'api/user/loginByRefreshToken', model).subscribe((response: any) => {
        var decoded = jwt_decode(response.token) as any;
        //const decodedToken = this.helper.decodeToken(response.token);
        this.Nume = decoded.Nume;
        this.Prenume = decoded.Prenume;
        this.Email = decoded.Email;
        this.Id = decoded.Id;
        this.sessionData.user = this.Nume + " " + this.Prenume;
        localStorage.setItem('Token', response.token);
        localStorage.setItem('RefreshToken', response.refreshToken);
        //this.assignUserRole(decoded.Id);
        this.assignUserValues(decoded.Id);
        this.getTotalStocksValue();
        this.getProjectProfitability();
        this.getProiecteByStatus();
        this.getTotalValue();
        this.getCriticStocks();
        this.getGestiunePrincipala(response.token);
        this.getAllUsers();
        this.getOferte();
        this.getProiecte();
        this.getProiecteUser(decoded.Id);
        this.getTabloterieGestiuneTabloterie();
        this.getServiciiManopera();
        this.getListaProducatori();
        this.getGestiunePersonalaByUserId(this.Id);
        this.getProiecteByClientId(this.Id)
      });
  }

  assignUserValues(id: number){
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }
    this.http.get<any>(this.appUrl + 'api/user/getUserById/' + id, header).subscribe((data) => {
      this.sessionData.user = data;
      this.userRole = data.rol.tip;
    });
  }
  getProiecteByClientId(id: any){
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }
      this.http.get<any>(this.appUrl + 'api/User/getProjectsByUserId/' + id, header).subscribe((data) => {
        if(this.userRole == "Client"){
          console.log(data);
          this.sessionData.dashboardClientInventar = data;  
          this.sessionData.clientProiectId = data.proiectList[0].id;
          let item: any;
          this.sessionData.dashboardClientInventar.proiectList.forEach((element: any) => {
            if(element.id == this.sessionData.clientProiectId){
              item = element;
            }
          });
          this.sessionData.stareOferte = item.stareOferte;
          this.sessionData.stareContracte = item.stareContracte;
          this.sessionData.stareFacturi = item.stareFacturi;
          this.sessionData.stareRapoarteDeLucru = item.stareRapoarteDeLucru;
          this.sessionData.stareDiverse = item.stareDiverse;
        }
      });
  }
  getProiecteUser(id: any){
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }
    let aux = [];
    this.http.get<any>(this.appUrl + 'api/ProiectUser/getProiectUser', header).subscribe((data) => {
      for(let i = 0; i < data.length; i++){
        if(this.userRole == "SuperAdmin"){
          this.sessionData.resourceData= [...this.sessionData.resourceData, {
            Id: data[i].id,
            Subject: data[i].proiect.denumireProiect,
            StartTime: data[i].dataInceput,
            EndTime: data[i].dataSfarsit,
            IsAllDay: true,
            ResourceId: data[i].userId,
            Description: data[i].descriere
          }];
        }
        else if(data[i].userId == id){
          this.sessionData.resourceData= [...this.sessionData.resourceData, {
            Id: data[i].id,
            Subject: data[i].proiect.denumireProiect,
            StartTime: data[i].dataInceput,
            EndTime: data[i].dataSfarsit,
            IsAllDay: true,
            ResourceId: data[i].userId,
            Description: data[i].descriere
          }];
        }
      }
      const colors: string[] = [
        '#ff8787', '#9775fa', '#748ffc', '#3bc9db', '#69db7c', '#fdd835', '#748ffc',
        '#9775fa', '#df5286', '#7fa900', '#fec200', '#5978ee', '#00bdae', '#ea80fc'
      ];
      for(let i = 0; i < data.length; i++){
        if(this.userRole == "SuperAdmin"){
          const n: number = Math.floor(Math.random() * colors.length);
          this.sessionData.resourceDataSource= [...this.sessionData.resourceDataSource, {
            Text: data[i].user.nume + " " + data[i].user.prenume,
            Id: data[i].userId,
            Color: colors[n],
          }];
        }
        else if(data[i].userId == id){
          const n: number = Math.floor(Math.random() * colors.length);
          this.sessionData.resourceDataSource= [...this.sessionData.resourceDataSource, {
            Text: data[i].user.nume + " " + data[i].user.prenume,
            Id: data[i].userId,
            Color: colors[n],
          }];
        }
        
      }

      aux = this.sessionData.resourceDataSource.reduce((acc: any[], val: { Id: any; }) => {
        if (!acc.find(el => el.Id === val.Id)) {
          acc.push(val);
        }
        return acc;
      }, []);

      this.sessionData.resourceDataSource = aux;
      if(this.userRole == "SuperAdmin"){
        this.sessionData.eventSettings = {
          dataSource:  this.sessionData.resourceData as Record<string, any>[]
        };
      }
      else{
        this.sessionData.eventSettings = {
          allowAdding: false, 
          allowEditing: false, 
          allowDeleting: false, 
          dataSource:  this.sessionData.resourceData as Record<string, any>[]
        };
      }
    });
  }
  getTotalStocksValue(): void{
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }
    this.http.get<any>(this.appUrl + 'api/GestiunePrincipala/getTotalStocksValue', header).subscribe((data) => {
      this.sessionData.totalStocksValue = data;
      this.sessionData.single = [
        {
          "name": "Valoare Stocuri",
          "value": 456000
          //this.sessionData.totalStocksValue
        },
      ];
    });

  }
  getServiciiManopera(): void{
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }
    this.http.get<any>(this.appUrl + 'api/ServiciuManopera/', header).subscribe((data) => {
      this.sessionData.serviciiManopera = data;
  });
  }
  getGestiunePersonalaByUserId(id: any): void{
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }
    this.http.get<any>(this.appUrl + 'api/GestiunePersonala/getGestiunePersonalaById/' + id, header).subscribe((data) => {
      this.sessionData.getGestiunePersonalaById = data;
    });
  }
  getProjectProfitability(): void{
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }
    this.http.get<any>(this.appUrl + 'api/Proiect/getProjectProfitability', header).subscribe((data) => {
      this.sessionData.projectProfitability = data;
    });
  }
  getListaProducatori(): void{
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }
    this.http.get<any>(this.appUrl + 'api/AprovizionareComandaProduse/getListaProducatori', header).subscribe((data) => {
      this.sessionData.listaProducatori = data;
    });
  }
  getProiecteByStatus(): void{
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }
    this.http.get<any>(this.appUrl + 'api/Proiect/getProiecteByStatus', header).subscribe((data) => {
      this.sessionData.projectsByStatus = data;
      this.sessionData.single3 = [
        {
          "name": "Proiecte Active",
          "value": this.sessionData.projectsByStatus.proiecteActive
        },
        {
          "name": "Proiecte Inactive",
          "value": this.sessionData.projectsByStatus.proiecteInactive
        },
        {
          "name": "Proiecte Finalizate",
          "value": this.sessionData.projectsByStatus.proiecteFinalizate
        }
      ];
    });

  }
  getCriticStocks(): void{
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }
    this.http.get<any>(this.appUrl + 'api/GestiunePrincipala/getCriticStocks', header).subscribe((data) => {
      this.sessionData.criticStocks = data;
    });
  }
  getTotalValue(): void{
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }
    this.http.get<any>(this.appUrl + 'api/Proiect/getTotalValue', header).subscribe((data) => {
      this.sessionData.totalProjectsValue = data;
      this.sessionData.single1 = [
        {
          "name": "Valoare Proiecte Active",
          "value": 700456
          //this.sessionData.totalProjectsValue
        },
      ];
    });
  }
  getTabloterieGestiuneTabloterie(): void{
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }
    this.http.get<any>(this.appUrl + 'api/TabloterieGestiuneTabloterie/getTabloterieGestiuneTabloterieInventory', header).subscribe((data) => {
      this.sessionData.gestiuneTabloterie = data;
    });
  }
  getAllUsers(): void{
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }
    this.http.get<any>(this.appUrl + 'api/User/getUsers', header).subscribe((data) => {
      this.sessionData.users = data;
      var countArray = 0;
      data.forEach(() => {
          countArray++;
      });
      this.sessionData.single2 = [
        {
          "name": "Număr Angajați",
          "value": 23
          //countArray
        },
      ];
    });
  }
  getProiecte(): void{
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }
    this.http.get<any>(this.appUrl + 'api/Proiect/', header).subscribe((data) => {
      this.sessionData.proiecte = data;
      for(let i = 0; i < data.length; i++){
        this.sessionData.projectDataSource= [...this.sessionData.projectDataSource, { text: this.sessionData.proiecte[i].denumireProiect, id: this.sessionData.proiecte[i].id, color: '#56ca85' }];
      }
    });
  }
  assignUserRole(id: number): void{
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }
    this.http.get<any>(this.appUrl + 'api/userrole/rol/' + id, header).subscribe((data) => {
      this.sessionData.userRole = data;
    });
  }
  getGestiunePrincipala(token: any): void{
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${token}`)
    }
    this.http.get<any>(this.appUrl + 'api/GestiunePrincipala/getGestiunePrincipalaGestiuneTranzitii', header).subscribe((data) => {
      this.sessionData.gestiunePrincipalaInventory = data.sort((a: { data: Date; }, b: { data: Date; }) => (a.data > b.data ? -1 : 1));
      this.sessionData.gestiunePrincipalaInventory.forEach((element: { id: any; }) => {
        if(element.id == this.sessionData.productId){
          this.sessionData.productDetails = element;
          this.sessionData.productDetails.data = formatDate(this.sessionData.productDetails.data, this.format, this.locale);
        }
      });
    });

  }
  getOferte(){
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.getToken()}`)
    }
    this.http.get<any>(this.appUrl + 'api/OfertareGestiuneOferte/getOferte', header).subscribe((data) => {
      this.sessionData.listaOferte = data;
    });
  }

  public getToken(): any {
    return localStorage.getItem('Token');
  }

  loggedIn(): boolean{
    this.token = localStorage.getItem("RefreshToken");
    if (this.token) {
      return true;
    }
    else {
      return false;
    }
  }
  logout() {
    localStorage.clear();
  }
  register(model: any){
    return  this.http.post(this.appUrl + 'api/user/register', model);
  }

}
