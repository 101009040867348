import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ofertare-gestiune-produse',
  templateUrl: './ofertare-gestiune-produse.component.html',
  styleUrls: ['./ofertare-gestiune-produse.component.css']
})
export class OfertareGestiuneProduseComponent implements OnInit {

    //#region Declarari
    userId: number | undefined;
    appUrl: string = environment.appUrl;
    selectedfile: File | undefined;
    message: string | undefined;
    cod: any;
    gPrincipala : any;
    p: number = 1;
    gOfertareModel: any = {
      id: '',
      cod: '',
      denumire: '',
      cantitate: '',
      pretUnitarAchizitie	: '',
      adaos: '',
      pretUnitarVanzare	: '',
      descriereProdus: '',
      producator: ''
    }

    fileName= 'ExcelSheet.xlsx';
    userName : any;
    buffer: any;
  //#endregion

    constructor(public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
      config.backdrop = 'static';
      config.keyboard = false;
    }

    ngOnInit(): void {
      this.sessionData.current_page_title = "GESTIUNE PRODUSE - OFERTARE"
      this.nav.show();
      var header = {
        headers: new HttpHeaders()
          .set('Authorization',  `Bearer ${this.authService.getToken()}`)
      }
      this.http.get<any>(this.appUrl + 'api/OfertareGestiuneProduse/', header).subscribe((data) => {
        this.sessionData.ofertareGestiuneProduse = data;
      });
    }

onFS(event: any){
  if(<File>event.target.files[0] != null){
    this.selectedfile = <File>event.target.files[0];
  }
}

//#region Adauga produs
addModal(content: any) {
  this.modalService.open(content);
}

AddResource(fAddCategory: NgForm){

  let myDate = new Date(Date.now());
  fAddCategory.value.File = "file";
  fAddCategory.value.Files = this.selectedfile;
  const formData = new FormData();
  console.log(fAddCategory.value.PretUnitarAchizitie);
  formData.append("Cod", fAddCategory.value.Cod.toString());
  formData.append("Denumire", fAddCategory.value.Denumire.toString());
  formData.append("Cantitate", fAddCategory.value.Cantitate);
  formData.append("PretUnitarAchizitie",fAddCategory.value.PretUnitarAchizitie.toString());
  formData.append("Adaos", fAddCategory.value.Adaos.toString());
  let pretVanzare = (parseFloat(fAddCategory.value.PretUnitarAchizitie) + (fAddCategory.value.PretUnitarAchizitie * (fAddCategory.value.Adaos / 100))).toString();
  formData.append("PretUnitarVanzare", pretVanzare);
  formData.append("DescriereProdus", fAddCategory.value.DescriereProdus.toString());
  formData.append("Producator", fAddCategory.value.Producator.toString());
  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  return this.http.post(this.appUrl + 'api/OfertareGestiuneProduse/', formData, header).subscribe(

    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );

}
//#endregion

//#region Editare produs

  editModal(content: any, gp: any) {
    this.modalService.open(content);
    this.gOfertareModel = {
      id: gp.id,
      cod: gp.cod,
      denumire: gp.denumire,
      cantitate: gp.cantitate,
      pretUnitarAchizitie	: gp.pretUnitarAchizitie,
      adaos: gp.adaos,
      pretUnitarVanzare	: gp.pretUnitarVanzare,
      descriereProdus: gp.descriereProdus,
      producator: gp.producator
    }
  }

  EditResource(fEditCategory: NgForm){
    fEditCategory.value.File = "file";
    const formData1 = new FormData();
    if(fEditCategory.value.Files != ""){
      fEditCategory.value.Files = this.selectedfile;
      formData1.append("Id", this.gOfertareModel.id);
      formData1.append("Cod", fEditCategory.value.Cod.toString());
      formData1.append("Denumire", fEditCategory.value.Denumire.toString());
      formData1.append("Cantitate", fEditCategory.value.Cantitate);
      formData1.append("PretUnitarAchizitie",fEditCategory.value.PretUnitarAchizitie.toString());
      formData1.append("Adaos", fEditCategory.value.Adaos.toString());
      let pretVanzare = (parseFloat(fEditCategory.value.PretUnitarAchizitie) + (fEditCategory.value.PretUnitarAchizitie * (fEditCategory.value.Adaos / 100))).toString();
      formData1.append("PretUnitarVanzare", pretVanzare);
      formData1.append("DescriereProdus", fEditCategory.value.DescriereProdus.toString());
      formData1.append("Producator", fEditCategory.value.Producator.toString());
    }
    else{
      formData1.append("Id", this.gOfertareModel.id);
      formData1.append("Cod", fEditCategory.value.Cod.toString());
      formData1.append("Denumire", fEditCategory.value.Denumire.toString());
      formData1.append("Cantitate", fEditCategory.value.Cantitate);
      formData1.append("PretUnitarAchizitie",fEditCategory.value.PretUnitarAchizitie.toString());
      formData1.append("Adaos", fEditCategory.value.Adaos.toString());
      let pretVanzare = (parseFloat(fEditCategory.value.PretUnitarAchizitie) + (fEditCategory.value.PretUnitarAchizitie * (fEditCategory.value.Adaos / 100))).toString();
      formData1.append("PretUnitarVanzare", pretVanzare);
      formData1.append("DescriereProdus", fEditCategory.value.DescriereProdus.toString());
      formData1.append("Producator", fEditCategory.value.Producator.toString());
    }
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    return this.http.put(this.appUrl + 'api/OfertareGestiuneProduse/' + this.gOfertareModel.id, formData1, header).subscribe(
      (response) => {    
        window.location.reload();
      },
      (error) => { 
        console.error('error caught in component')
        this.message = "Eroare"
      }
    );
  }

//#endregion

//#region Stergere Produs
  deleteModal(content: any, category: any) {
    this.modalService.open(content);
    this.gOfertareModel.id = category.id;
  }
  delete(event: any){
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    this.http.delete(this.appUrl + 'api/OfertareGestiuneProduse/' + this.gOfertareModel.id, header).subscribe(
      (response) => {    
        window.location.reload();
      },
      (error) => { 
        console.error('error caught in component')
        this.message = "Eroare"
      }
    );
  }
//#endregion

//#region Adauga Csv
addCsvModal(content: any) {
  this.modalService.open(content);
}

AddCsvResource(fAddCsvCategory: NgForm){

  let myDate = new Date(Date.now());
  fAddCsvCategory.value.File = "file";
  fAddCsvCategory.value.Files = this.selectedfile;
  const formData = new FormData();
  this.buffer = true;

  formData.append("CsvFile",  fAddCsvCategory.value.Files);

  return this.http.post(this.appUrl + 'api/OfertareGestiuneProduse/uploadCsv',  formData).subscribe(
  (response) => {    
    this.buffer = false
    this.message = "Fisierul a fost incarcat!"
  },
  (error) => { 
    this.buffer = false;
    this.message = "Eroare"
  }
);

}
//#endregion


Search(){
  if(this.cod != ""){
    this.sessionData.ofertareGestiuneProduse = this.sessionData.ofertareGestiuneProduse.filter((res: { cod: string; }) => {
      return res.cod.toLocaleLowerCase().match(this.cod.toLocaleLowerCase());
    });
  }else if(this.cod == ""){
    this.ngOnInit();
  }

}
refresh(){
  if(this.message == null){
    this.modalService.dismissAll();
  }
  else{
    window.location.reload();
  }
}
}
