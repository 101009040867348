import { SidebarComponent } from './components/sidebar/sidebar.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {NgxPaginationModule} from 'ngx-pagination'; // <-- import the module
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { MatToolbarModule } from '@angular/material/toolbar';
import { GestiunePrincipalaComponent } from './components/materiale/gestiune-principala/gestiune-principala.component';
import { GestiunePersonalaComponent } from './components/materiale/gestiune-personala/gestiune-personala.component';
import { GestiuneProiecteComponent } from './components/materiale/gestiune-proiecte/gestiune-proiecte.component';
import { GestiuneAngajatiComponent } from './components/materiale/gestiune-angajati/gestiune-angajati.component';
import { GestiuneTransferComponent } from './components/materiale/gestiune-transfer/gestiune-transfer.component';
import { GestiuneVanzariComponent } from './components/materiale/gestiune-vanzari/gestiune-vanzari.component';
import { RezervareProiecteComponent } from './components/materiale/rezervare-proiecte/rezervare-proiecte.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ProductDetailComponent } from './components/materiale/product-detail/product-detail.component';
import { DatePipe } from '@angular/common'
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { ProiecteComponent } from './components/clienti/proiecte/proiecte.component';
import { ListaAngajatiComponent } from './components/proiecte/lista-angajati/lista-angajati.component';
import { TabelPrezentaComponent } from './components/prezenta/tabel-prezenta/tabel-prezenta.component';
import { AddPrezentaBirouComponent } from './components/prezenta/add-prezenta-birou/add-prezenta-birou.component';
import { AddPrezentaAtelierComponent } from './components/prezenta/add-prezenta-atelier/add-prezenta-atelier.component';
import { AddPrezentaProiectComponent } from './components/prezenta/add-prezenta-proiect/add-prezenta-proiect.component';
import { OfertareGestiuneProduseComponent } from './components/ofertare/ofertare-gestiune-produse/ofertare-gestiune-produse.component';
import { NgxChartsModule }from '@swimlane/ngx-charts';
import { AuthGuard } from './guard/auth-guard.service';
import { ChatComponent } from './components/proiecte/chat/chat.component';
import { DocumentatieComponent } from './components/proiecte/documentatie/documentatie.component';
import { ScheduleModule } from '@syncfusion/ej2-angular-schedule';
// import { SignaturePadModule } from 'angular2-signaturepad';
import { ArticoleManoperaComponent } from './components/ofertare/articole-manopera/articole-manopera.component';
import { ListaClientiComponent } from './components/clienti/lista-clienti/lista-clienti.component';
import { OfertareGestiuneOferteComponent } from './components/ofertare/ofertare-gestiune-oferte/ofertare-gestiune-oferte.component';
import { OfertareGestiuneDescrieriComponent } from './components/ofertare/ofertare-gestiune-descrieri/ofertare-gestiune-descrieri.component';
import { OfertareGestiuneEchipamenteComponent } from './components/ofertare/ofertare-gestiune-echipamente/ofertare-gestiune-echipamente.component';
import { OfertareGestiuneVizualizariComponent } from './components/ofertare/ofertare-gestiune-vizualizari/ofertare-gestiune-vizualizari.component';
import { OfertareCreareOfertaComponent } from './components/ofertare/ofertare-creare-oferta/ofertare-creare-oferta.component';
import { OfertareEditareOfertaComponent } from './components/ofertare/ofertare-editare-oferta/ofertare-editare-oferta.component';
import { GestiuneAprovizionareComponent } from './components/aprovizionare/gestiune-aprovizionare/gestiune-aprovizionare.component';
import { GestiuneComandaComponent } from './components/aprovizionare/gestiune-comanda/gestiune-comanda.component';
import { GestiuneAprovizionareComandaComponent } from './components/aprovizionare/gestiune-aprovizionare-comanda/gestiune-aprovizionare-comanda.component';
import { TabloterieProiecteComponent } from './components/tabloterie/tabloterie-proiecte/tabloterie-proiecte.component';
import { TabloterieTablouriComponent } from './components/tabloterie/tabloterie-tablouri/tabloterie-tablouri.component';
import { TabloterieProduseComponent } from './components/tabloterie/tabloterie-produse/tabloterie-produse.component';
import { GestiuneTabloterieComponent } from './components/tabloterie/gestiune-tabloterie/gestiune-tabloterie.component';
import { PrezentaTabloterieComponent } from './components/prezenta/prezenta-tabloterie/prezenta-tabloterie.component';
import { ListaProduseDecomandatComponent } from './components/aprovizionare/lista-produse-decomandat/lista-produse-decomandat.component';
import { SolicitaServiciileNoastreComponent } from './components/solicita-serviciile-noastre/solicita-serviciile-noastre.component';
import { DatePersonaleComponent } from './components/date-personale/date-personale.component';
import { DashboardClientComponent } from './components/clienti/dashboard-client/dashboard-client.component';
import { SignaturePadModule } from 'angular2-signaturepad';
import { ProiecteClientiComponent } from './components/proiecte-clienti/proiecte-clienti.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { RegisterUserComponent } from './components/register-user/register-user.component';
import { GestiuneAprovizionareRezervariComponent } from './components/aprovizionare/gestiune-aprovizionare-rezervari/gestiune-aprovizionare-rezervari.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { OferteDashboardComponent } from './components/clienti/oferte-dashboard/oferte-dashboard.component';
import { ContracteDashboardComponent } from './components/clienti/contracte-dashboard/contracte-dashboard.component';
import { FacturiDashboardComponent } from './components/clienti/facturi-dashboard/facturi-dashboard.component';
import { RapoartedelucruDashboardComponent } from './components/clienti/rapoartedelucru-dashboard/rapoartedelucru-dashboard.component';
import { DiverseDashboardComponent } from './components/clienti/diverse-dashboard/diverse-dashboard.component';
import { GestiuneFotovoltaiceComponent } from './components/fotovoltaice/gestiune-fotovoltaice/gestiune-fotovoltaice.component';
import { FotovoltaiceProiecteComponent } from './components/fotovoltaice/fotovoltaice-proiecte/fotovoltaice-proiecte.component';
import { FotovoltaiceProduseComponent } from './components/fotovoltaice/fotovoltaice-produse/fotovoltaice-produse.component';
import { PrezentaFotovoltaiceComponent } from './components/prezenta/prezenta-fotovoltaice/prezenta-fotovoltaice.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    SidebarComponent,
    GestiunePrincipalaComponent,
    GestiunePersonalaComponent,
    GestiuneProiecteComponent,
    GestiuneAngajatiComponent,
    GestiuneTransferComponent,
    GestiuneVanzariComponent,
    RezervareProiecteComponent,
    ProductDetailComponent,
    ProiecteComponent,
    ListaAngajatiComponent,
    TabelPrezentaComponent,
    AddPrezentaBirouComponent,
    AddPrezentaAtelierComponent,
    AddPrezentaProiectComponent,
    OfertareGestiuneProduseComponent,
    ChatComponent,
    DocumentatieComponent,
    ArticoleManoperaComponent,
    ListaClientiComponent,
    OfertareGestiuneOferteComponent,
    OfertareGestiuneDescrieriComponent,
    OfertareGestiuneEchipamenteComponent,
    OfertareGestiuneVizualizariComponent,
    OfertareCreareOfertaComponent,
    OfertareEditareOfertaComponent,
    GestiuneAprovizionareComponent,
    GestiuneComandaComponent,
    GestiuneAprovizionareComandaComponent,
    GestiuneTabloterieComponent,
    TabloterieProiecteComponent,
    TabloterieTablouriComponent,
    TabloterieProduseComponent,
    PrezentaTabloterieComponent,
    ListaProduseDecomandatComponent,
    SolicitaServiciileNoastreComponent,
    DatePersonaleComponent,
    DashboardClientComponent,
    ProiecteClientiComponent,
    RegisterUserComponent,
    GestiuneAprovizionareRezervariComponent,
    ResetPasswordComponent,
    ChangePasswordComponent,
    OferteDashboardComponent,
    ContracteDashboardComponent,
    FacturiDashboardComponent,
    RapoartedelucruDashboardComponent,
    DiverseDashboardComponent,
    GestiuneFotovoltaiceComponent,
    FotovoltaiceProiecteComponent,
    FotovoltaiceProduseComponent,
    PrezentaFotovoltaiceComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AppRoutingModule,
    NgxPaginationModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatSidenavModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    TooltipModule.forRoot(),
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    NgxChartsModule,
    ScheduleModule,
    SignaturePadModule,
    MatProgressSpinnerModule
  ],
  providers: [DatePipe, AuthGuard, {provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
