import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-gestiune-transfer',
  templateUrl: './gestiune-transfer.component.html',
  styleUrls: ['./gestiune-transfer.component.css']
})
export class GestiuneTransferComponent implements OnInit {

  //#region Declarari
  userId: number | undefined;
  appUrl: string = environment.appUrl;
  selectedfile: File | undefined;
  message: string | undefined;
  searchItem: any;
  gPrincipala : any;
  p: number = 1;
  gTransferModel: any = {
    id: '',
    activ: '',
    cantitate: '',
    data: '',
    gestiunePrincipala: '',
    numeUser: '',
    status: '',
    userId: '',
  }
  addGPersonalaModel: any = {
    gestiuneTransferId: '',
    cantitate: '',
    userId: '',
    numeUser: '',
    gestiunePrincipalaId: ''
  };
  returGPrincipalaModel: any = {
    gestiunePrincipalaId: '',
    gestiuneTransferId: '',
    numeUser: '',
    cantitate: ''
  };
  returRezervareProiectModel: any = {
    GestiunePrincipalaId: '',
    gestiuneTransferId: '',
    numeUser: '',
    cantitate: '',
    proiectId: '',
  };

  fileName= 'ExcelSheet.xlsx';
  userName : any;
  isChecked: any = false;
  excelModel: any = {
    dataInceput: '',
    dataSfarsit: ''
  }
  buffer: any;

//#endregion

  constructor(public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
    config.backdrop = 'static';
    config.keyboard = false;
   }

   ngOnInit(): void {
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    this.sessionData.current_page_title = "GESTIUNE TRANSFER"
    this.nav.show();

    this.http.get<any>(this.appUrl + 'api/GestiuneTransfer/getGestiuneTransfer', header).subscribe((data) => {
      this.sessionData.gestiuneTransfer = data.sort((a: { data: Date; }, b: { data: Date; }) => (a.data > b.data ? -1 : 1));
      console.log(data);
    });

  }


//#region Transfera in Gestiune Personala

addToGPersonalaModal(content: any, gp: any) {
  this.modalService.open(content);
  this.gTransferModel = {
    id: gp.id,
    activ: gp.activ,
    cantitate: gp.cantitate,
    data: gp.data,
    gestiunePrincipala: gp.gestiunePrincipala,
    numeUser: gp.numeUser,
    status: gp.status,
    userId: gp.userId
  }
}

AddGPersonalaResource(fAddGPersonala: NgForm){

    this.addGPersonalaModel = {
      gestiuneTransferId: this.gTransferModel.id,
      cantitate: this.gTransferModel.cantitate,
      userId: this.gTransferModel.userId,
      numeUser: this.gTransferModel.numeUser,
      gestiunePrincipalaId: this.gTransferModel.gestiunePrincipala.id
    }
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
  return this.http.post(this.appUrl + 'api/GestiuneTransfer/addGestiunePersonala', this.addGPersonalaModel, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );



}

//#endregion

//#region Retur Produs
  returModal(content: any, gp: any) {
    this.modalService.open(content);
    this.gTransferModel = {
      id: gp.id,
      activ: gp.activ,
      cantitate: gp.cantitate,
      data: gp.data,
      gestiunePrincipala: gp.gestiunePrincipala,
      numeUser: gp.numeUser,
      status: gp.status,
      userId: gp.userId
    }
  }
  DeleteResource(fReturProdus: any){

    if(fReturProdus.value.gestiunePrincipala == true && fReturProdus.value.rezervareProiect == true){
      this.message = "Alege o singura optiune!"
    }
    else if(fReturProdus.value.gestiunePrincipala == true){
      this.returGPrincipalaModel = {
        gestiunePrincipalaId: this.gTransferModel.gestiunePrincipala.id,
        gestiuneTransferId: this.gTransferModel.id,
        numeUser: this.gTransferModel.numeUser,
        cantitate: this.gTransferModel.cantitate
      }
      var header = {
        headers: new HttpHeaders()
          .set('Authorization',  `Bearer ${this.authService.getToken()}`)
      }
      this.http.post(this.appUrl + 'api/GestiuneTransfer/deleteToGestiunePrincipala/' + this.gTransferModel.id,  this.returGPrincipalaModel, header).subscribe(
        (response) => {    
          window.location.reload();
        },
        (error) => { 
          console.error('error caught in component')
          this.message = "Eroare"
        }
      );
    }
    else if(fReturProdus.value.rezervareProiect == true){
      this.returRezervareProiectModel = {
        gestiunePrincipalaId: this.gTransferModel.gestiunePrincipala.id,
        gestiuneTransferId: this.gTransferModel.id,
        numeUser: this.gTransferModel.numeUser,
        cantitate: this.gTransferModel.cantitate,
        proiectId: fReturProdus.value.Proiect
      }
      this.message = "Produsul a fost returnat!"
      var header = {
        headers: new HttpHeaders()
          .set('Authorization',  `Bearer ${this.authService.getToken()}`)
      }
      this.http.post(this.appUrl + 'api/GestiuneTransfer/deleteToRezervarePeProiect/' + this.gTransferModel.id,  this.returRezervareProiectModel, header).subscribe(
        (response) => {    
          window.location.reload();
        },
        (error) => { 
          console.error('error caught in component')
          this.message = "Eroare"
        }
      );
    }


  }
//#endregion

//#region Transfera in Gestiune Personala

addToGTabloterieModal(content: any, gp: any) {
  this.modalService.open(content);
  this.gTransferModel = {
    id: gp.id,
    activ: gp.activ,
    cantitate: gp.cantitate,
    data: gp.data,
    gestiunePrincipala: gp.gestiunePrincipala,
    numeUser: gp.numeUser,
    status: gp.status,
    userId: gp.userId
  }
}

AddGTabloterieResource(fAddGTabloterie: NgForm){

    this.addGPersonalaModel = {
      gestiuneTransferId: this.gTransferModel.id,
      cantitate: this.gTransferModel.cantitate,
      userId: this.gTransferModel.userId,
      numeUser: this.gTransferModel.numeUser,
      gestiunePrincipalaId: this.gTransferModel.gestiunePrincipala.id
    }
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
  return this.http.post(this.appUrl + 'api/GestiuneTransfer/addTabloterieGestiuneTabloterie', this.addGPersonalaModel, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
}

//#endregion

//#region excel proiect
exportExcel(content: any) : void {
  this.modalService.open(content);
}

ExportExcelResource(fexportExcel: any){
 
    this.excelModel = {
      dataInceput: this.adjustDateForTimeOffset(fexportExcel.value.DataInceput),
      dataSfarsit: this.adjustDateForTimeOffset(fexportExcel.value.DataSfarsit)
    }

  this.http.post(this.appUrl + 'api/GestiuneTransfer/raportGestiuneProiectDupaProiect', this.excelModel, { responseType: 'blob'} ).subscribe((data) => {
    const blob = new Blob([data], { type: 'application/octet-stream'});
    const url= window.URL.createObjectURL(blob);
    window.open(url);
  });

}
//#endregion


Search(){
  if(this.searchItem != ""){
    this.sessionData.gestiuneTransfer = this.sessionData.gestiuneTransfer.filter((res: any) => {
      return res.numeUser.toLocaleLowerCase().match(this.searchItem.toLocaleLowerCase()) || res.gestiunePrincipala.codIntern.toLocaleLowerCase().match(this.searchItem.toLocaleLowerCase()) || res.gestiunePrincipala.denumire.toLocaleLowerCase().match(this.searchItem.toLocaleLowerCase());
    });
  }else if(this.searchItem == ""){
    this.ngOnInit();
  }

}

//enable proiecte
checkValue(event: any){
}

refresh(){
  if(this.message == null){
    this.modalService.dismissAll();
  }
  else{
    window.location.reload();
  }
}

adjustDateForTimeOffset(dateToAdjust: any) {
  var offsetMs = dateToAdjust.getTimezoneOffset() * 60000;
  return new Date(dateToAdjust.getTime() - offsetMs);
}

//#region Adauga Csv
addCsvModal(content: any) {
  this.modalService.open(content);
}

AddCsvResource(fAddCsvCategory: NgForm){

  let myDate = new Date(Date.now());
  fAddCsvCategory.value.File = "file";
  fAddCsvCategory.value.Files = this.selectedfile;
  const formData = new FormData();
  this.buffer = true;

  formData.append("CsvFile",  fAddCsvCategory.value.Files);

  return this.http.post(this.appUrl + 'api/GestiunePrincipala/uploadCsv',  formData).subscribe(
  (response) => {    
    this.buffer = false
    this.message = "Fisierul a fost incarcat!"
  },
  (error) => { 
    this.buffer = false;
    this.message = "Eroare"
  }
);

}
//#endregion


}


