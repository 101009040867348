import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit {

    //#region Declarari
    projectOption: any = "Activ";
    userId: number | undefined;
    appUrl: string = environment.appUrl;
    selectedfile: File | undefined;
    message: string | undefined;
    denumire: any;
    gPrincipala : any;
    p: number = 1;

    fileName= 'ExcelSheet.xlsx';
    userName : any;
    chatModel: any;
  nativeWindow: any;

  chatModelId: any;
  //#endregion

    constructor(public datepipe: DatePipe, public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
      config.backdrop = 'static';
      config.keyboard = false;
      sessionData.projectId = localStorage.getItem('ProjectId');
    }
    ngOnInit(): void {
      this.sessionData.current_page_title = localStorage.getItem('ProjectName') + " - CHAT";
      this.nav.show();
      var header = {
        headers: new HttpHeaders()
          .set('Authorization',  `Bearer ${this.authService.getToken()}`)
      }
      this.http.get<any>(this.appUrl + 'api/FeedProiect/getFeedByProjectId/' + this.sessionData.projectId, header).subscribe((data) => {
        this.sessionData.chat = data;
      });
    }

    onFS(event: any){
      if(<File>event.target.files[0] != null){
        this.selectedfile = <File>event.target.files[0];
      }
    }


    //#region Adauga proiect
    addModal(content: any) {
      this.modalService.open(content);
    }

    AddResource(fAddCategory: NgForm){

      let myDate = new Date(Date.now());
      fAddCategory.value.File = "file";
      fAddCategory.value.Files = this.selectedfile;
      const formData = new FormData();
      formData.append("Titlu", fAddCategory.value.Titlu.toString());
      formData.append("Descriere", fAddCategory.value.Descriere.toString());
      formData.append("Data", myDate.toDateString());
      formData.append("File", fAddCategory.value.File.toString());
      formData.append("Files",  fAddCategory.value.Files);
      formData.append("NumeUser", (this.sessionData.user.nume + " " + this.sessionData.user.prenume).toString());
      formData.append("ProiectId", this.sessionData.projectId);
      var header = {
        headers: new HttpHeaders()
          .set('Authorization',  `Bearer ${this.authService.getToken()}`)
      }
      return this.http.post(this.appUrl + 'api/FeedProiect/', formData, header).subscribe(
        (response) => {    
          this.message = "Postarea a fost creată!"
        },
        (error) => { 
          console.error('error caught in component')
          this.message = "Eroare"
        }
      );

    }
    //#endregion

    //#region Stergere Produs
deleteModal(content: any, category: any) {
  this.modalService.open(content);
  this.chatModelId = category.id;
}
delete(event: any){
 
  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  this.http.delete(this.appUrl + 'api/FeedProiect/' + this.chatModelId, header).subscribe(
    (response) => {    
      this.message = "Postarea a fost ștearsă!"
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
}
//#endregion



    refresh(){
      window.location.reload();
    }

}
