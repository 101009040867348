import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-prezenta-tabloterie',
  templateUrl: './prezenta-tabloterie.component.html',
  styleUrls: ['./prezenta-tabloterie.component.css']
})
export class PrezentaTabloterieComponent implements OnInit {
  userId: number | undefined;
  appUrl: string = environment.appUrl;
  selectedfile: File | undefined;
  message: string | undefined;
  denumire: any;
  p: number = 1;
  today: any;
  todayInput: any;
  yesterday: any;
  userPrezentaModel: any = {
    id: '',
    tablouId: '',
    data: '',
    oreLucrate: '',
    userId: ''
  }
  numeProiectAles: any;
  excelProiectModel: any = {
    proiectId: '',
    dataInceput: '',
    dataSfarsit: ''
   }
   excelUserId: any;
   proiectId: any;
   excelAngajatModel: any = {
    userId: '',
    dataInceput: '',
    dataSfarsit: ''
   }
   excelTablouModel: any = {
    tablouId: '',
    dataInceput: '',
    dataSfarsit: ''
   }
   idProiectAles: any;
   listaTablouri: any = [];
  numeProiectTablou: any;
  constructor(public datepipe: DatePipe, public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {

    this.sessionData.current_page_title = "PREZENȚĂ TABLOTERIE"
    this.nav.show();
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    this.http.get<any>(this.appUrl + 'api/TabloteriePrezenta/getTabloteriePrezenta', header).subscribe((data) => {
      this.sessionData.prezentaTabloterie = data.sort((a: { data: number; }, b: { data: number; }) => (a.data > b.data ? -1 : 1));
    });
  }

  //#region Editare produs

editModal(content: any, gp: any) {
  this.numeProiectTablou = gp.tabloterieProiect.denumire + " - " + gp.tabloterieTablou.denumire;
  this.todayInput = new Date;
  this.today = new Date;
  this.yesterday = new Date;
  this.yesterday.setDate(this.today.getDate() - 1);
  this.modalService.open(content);
  this.userPrezentaModel = {
    id: gp.id,
    tablouId: gp.tabloterieTablou.id,
    data: gp.data,
    oreLucrate: gp.oreLucrate,
    userId: gp.user.id
  }

}

EditResource(fEditCategory: NgForm){

   this.userPrezentaModel.data = new Date(fEditCategory.value.Data);
   this.userPrezentaModel.oreLucrate = fEditCategory.value.OreLucrate;

   var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  return this.http.put(this.appUrl + 'api/TabloteriePrezenta/' + this.userPrezentaModel.id, this.userPrezentaModel, header).subscribe(
    (response) => {    
      this.message = "Prezența a fost editată!"
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
}

//#endregion

  //#region Stergere Produs
deleteModal(content: any, category: any) {
  this.modalService.open(content);
  this.userPrezentaModel.id = category.id;
}
delete(event: any){
  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  this.http.delete(this.appUrl + 'api/TabloteriePrezenta/' + this.userPrezentaModel.id, header).subscribe(
    (response) => {    
      this.message = "Prezența a fost ștearsă!"
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
}
//#endregion

  //#region excel proiect
  exportExcelProiect(content: any) : void {
    this.modalService.open(content);
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    this.http.get<any>(this.appUrl + 'api/TabloterieProiect/getProiecteTablouri', header).subscribe((data) => {
      this.sessionData.proiecteTablouri = data;
   });
  }

  ExportExcelProiectResource(fexportExcelProiect: any){
    if(fexportExcelProiect.value.gestiunePrincipala == true && fexportExcelProiect.value.rezervareProiect == true){
      this.message = "Alege o singura optiune!"
    }

    else if(fexportExcelProiect.value.gestiunePrincipala == true){
      this.excelProiectModel = {
        proiectId: 0,
        dataInceput: this.adjustDateForTimeOffset(fexportExcelProiect.value.DataInceput),
        dataSfarsit: this.adjustDateForTimeOffset(fexportExcelProiect.value.DataSfarsit)
      }

    }
    else if(fexportExcelProiect.value.rezervareProiect == true){

      this.sessionData.proiecteTablouri.tabloterieProiectList.forEach((element: any) => {
        if(element.denumire == fexportExcelProiect.value.Proiect){
          this.proiectId = element.id;
        }
      });
      this.excelProiectModel = {
        proiectId: this.proiectId,
        dataInceput: this.adjustDateForTimeOffset(fexportExcelProiect.value.DataInceput),
        dataSfarsit: this.adjustDateForTimeOffset(fexportExcelProiect.value.DataSfarsit)
      }
    }

    this.http.post(this.appUrl + 'api/TabloteriePrezenta/raportTabloteriePrezentaDupaProiect', this.excelProiectModel, { responseType: 'blob'} ).subscribe((data) => {
      const blob = new Blob([data], { type: 'application/octet-stream'});
      const url= window.URL.createObjectURL(blob);
      window.open(url);
    });

  }
  //#endregion
  
  //#region excel angajat
exportExcelAngajat(content: any) : void {
  this.modalService.open(content);
}

ExportExcelAngajatResource(fexportExcelAngajat: any){
  this.sessionData.users.forEach((element: { nume: string; prenume: string; id: any; }) => {
    if(element.nume + " " + element.prenume == fexportExcelAngajat.value.User){
      this.excelUserId = element.id;
    }
  });
  this.excelAngajatModel = {
    userId: this.excelUserId,
    dataInceput: this.adjustDateForTimeOffset(fexportExcelAngajat.value.DataInceput),
    dataSfarsit: this.adjustDateForTimeOffset(fexportExcelAngajat.value.DataSfarsit)
  }

  this.http.post(this.appUrl + 'api/TabloteriePrezenta/raportTabloteriePrezentaDupaAngajat', this.excelAngajatModel, { responseType: 'blob'} ).subscribe((data) => {
    const blob = new Blob([data], { type: 'application/octet-stream'});
    const url= window.URL.createObjectURL(blob);
    window.open(url);
  });
}
//#endregion

   //#region excel tablou
   exportExcelTablou(content: any) : void {
    this.modalService.open(content);
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    this.http.get<any>(this.appUrl + 'api/TabloterieProiect/getProiecteTablouri', header).subscribe((data) => {
      this.sessionData.proiecteTablouri = data;
   });
  }

  ExportExcelTablouResource(fexportExcelTablou: any){
   
    this.excelTablouModel = {
      tablouId: fexportExcelTablou.value.Tablou,
      dataInceput: this.adjustDateForTimeOffset(fexportExcelTablou.value.DataInceput),
      dataSfarsit: this.adjustDateForTimeOffset(fexportExcelTablou.value.DataSfarsit)
    }
  
    this.http.post(this.appUrl + 'api/TabloteriePrezenta/raportTabloteriePrezentaDupaTablou', this.excelTablouModel, { responseType: 'blob'} ).subscribe((data) => {
      const blob = new Blob([data], { type: 'application/octet-stream'});
      const url= window.URL.createObjectURL(blob);
      window.open(url);
    });
  }
  //#endregion

  changeProiect(){
    this.listaTablouri = [];
    this.sessionData.proiecteTablouri.tabloterieProiectList.forEach((element: any) => {
      if(element.denumire == this.numeProiectAles){
        this.idProiectAles = element.id;
      }
    }); 
    this.sessionData.proiecteTablouri.tabloterieTablouList.forEach((element: any) => {
      if(element.tabloterieProiectId == this.idProiectAles){
        this.listaTablouri.push(element);
      }
    });
  }
refresh(){
  if(this.message == null){
    this.modalService.dismissAll();
  }
  else{
    window.location.reload();
  }
}

adjustDateForTimeOffset(dateToAdjust: any) {
  var offsetMs = dateToAdjust.getTimezoneOffset() * 60000;
  return new Date(dateToAdjust.getTime() - offsetMs);
  }

}
