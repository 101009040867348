import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-lista-produse-decomandat',
  templateUrl: './lista-produse-decomandat.component.html',
  styleUrls: ['./lista-produse-decomandat.component.css']
})
export class ListaProduseDecomandatComponent implements OnInit {
  userId: number | undefined;
  appUrl: string = environment.appUrl;
  selectedfile: File | undefined;
  message: string | undefined;
  comandId: any;
  comandName: any;
  comandStatus: any;
  aprovizionareModelUpdate: any = {
    id: '',
    cantitateOfertaPrincipala: '',
    cantitateProdus: '',
    status: '',
    ofertareGestiuneProduseId: '',
    aprovizionareComandaId: ''
  }
  excelProiectModel: any = {
    producator: ''
  }
  model: any = {
    id: '',
    cod: '',
    cantitate: '',
    aprovizioanareListaDeComandat: '',
  }
  modelGestiuneComenzi: any;
  addRezervareModel: any = {
    id: '',
    aprovizionareComandaId: '',
    aprovizionareComandaProduseId: '',
    ofertareGestiuneProduseId: '',
    cantitateProdus: ''
  };
  produsGestiuneComandaId: any;
  constructor(public datepipe: DatePipe, public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
  }

  ngOnInit(): void {
    this.sessionData.current_page_title =  'LISTĂ PRODUSE "DE COMANDAT"';
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    this.http.get<any>(this.appUrl + 'api/AprovizioanareListaDeComandat/getAprovizionareListaDeComandat/', header).subscribe((data) => {
       this.sessionData.listaProduseComenziDeComandat = data;
    });
  }

  //#region excel proiect
exportExcel(content: any) : void {
  this.modalService.open(content);
}

ExportExcelResource(fexportExcel: any){
 
  this.excelProiectModel = {
    producator: fexportExcel.value.Producator
  }
  this.http.post(this.appUrl + 'api/AprovizioanareListaDeComandat/raportStatus', this.excelProiectModel, { responseType: 'blob'}).subscribe((data) => {
    const blob = new Blob([data], { type: 'application/octet-stream'});
    const url= window.URL.createObjectURL(blob);
    window.open(url);
  });

}
//#endregion

//#region Transfera in Rezervare pe Proiect

addToRezervareModal(content: any, gp: any) {
  this.modalService.open(content);
  this.model = {
    id: gp.id,
    cod: gp.cod,
    denumire: gp.denumire,
    cantitate: gp.cantitateProdus,
    aprovizioanareListaDeComandat: gp.aprovizioanareListaDeComandat
  }

  this.sessionData.listaGestiuneComenzi.forEach((element: { id: any; }) => {
    if(element.id == this.model.id){
      this.modelGestiuneComenzi = element;
    }
  });
}

AddRezervareResource(fAddGTransfer: NgForm){

  this.addRezervareModel = {
    id: this.model.aprovizioanareListaDeComandat.id,
    aprovizionareComandaId: this.model.aprovizioanareListaDeComandat.aprovizionareComandaId,
    aprovizionareComandaProduseId: this.model.aprovizioanareListaDeComandat.aprovizionareComandaProduseId,
    ofertareGestiuneProduseId: this.model.aprovizioanareListaDeComandat.ofertareGestiuneProduseId,
    cantitateProdus: this.model.aprovizioanareListaDeComandat.cantitateProdus
  };
  console.log(this.addRezervareModel);
  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  return this.http.post(this.appUrl + 'api/AprovizioanareListaDeComandat/addToAprovizionareGestiuneComenzi', this.addRezervareModel, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );

}

//#endregion

//#region Stergere Produs
deleteModal(content: any, category: any) {
  this.modalService.open(content);
  this.produsGestiuneComandaId = category.id;
}
delete(event: any){
  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  this.http.delete(this.appUrl + 'api/AprovizionareGestiuneComenzi/' + this.produsGestiuneComandaId, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => {
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
}
//#endregion


refresh(){
  if(this.message == null){
    this.modalService.dismissAll();
  }
  else{
    window.location.reload();
  }
}

}
