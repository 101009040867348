import { SessionDataService } from './../../session-data.service';
import { Component, HostListener, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NavbarService } from 'src/app/services/navbar.service';
import { ChartType } from 'chart.js';
import { BrowserModule } from '@angular/platform-browser';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { extend } from '@syncfusion/ej2-base';
import { DatePipe } from '@angular/common';
import {
  ScheduleComponent, EventSettingsModel, GroupModel, DayService, WeekService, MonthService, DragAndDropService, View, TimelineMonthService, ResizeService, ActionEventArgs
} from '@syncfusion/ej2-angular-schedule';
import { resourceData, timelineResourceData } from '../../../data';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { SignaturePadModule } from 'angular2-signaturepad';
import { SignaturePad } from 'angular2-signaturepad';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  // encapsulation: ViewEncapsulation.None,
  providers: [TimelineMonthService, ResizeService, DragAndDropService]

})
export class HomeComponent implements OnInit {

  view: any;
  view1: any;
  view2: any;
  view3: any;
  @HostListener('window:resize', ['$event'])
  sizeChange(event : any) {
    if(event.target.innerWidth < 800){
      this.view = [innerWidth / 1.3, 250];
      this.view1 = [innerWidth / 1.3, 250];
      this.view2 = [innerWidth / 1.3, 250];
      this.view3 = [innerWidth / 1.3, 250];

    }
    else{
      this.view = [innerWidth / 4, 250];
      this.view1 = [innerWidth / 4, 250];
      this.view2 = [innerWidth / 4, 250];
      this.view3 = [innerWidth / 4, 250];

    }
    console.log('size changed.', event.target.innerWidth);
  }
  //#region Declarari dashboard
  appUrl: string = environment.appUrl;
  legend: boolean = true;
  legendPosition: any = 'below';

  colorScheme = {
    domain: ['#5AA454']
  };
  legend1: boolean = true;
  legendPosition1: any = 'below';

  colorScheme1 = {
    domain: ['#E44D25']
  };
  legend2: boolean = true;
  legendPosition2: any = 'below';
  colorScheme2 = {
    domain: ['#7aa3e5']
  };


  codProduct: string | undefined;


  // options
  gradient3: boolean = true;
  showLegend3: boolean = true;
  showLabels3: boolean = true;
  isDoughnut3: boolean = false;
  legendPosition3: any = 'below';

  colorScheme3 = {
    domain: ['#5AA454', '#A10A28', '#C7B42C']
  };

  //#endregion
  
  proiectUserModel : any = {
    proiectId : '',
    userId : '',
    dataInceput: '',
    dataSfarsit: '',
    descriere: ''
  }

  proiectUserModelUpdate : any = {
    id: '',
    proiectId : '',
    userId : '',
    dataInceput: '',
    dataSfarsit: '',
    descriere: ''
  }
  listaClienti: any = [];
  listaProiecte: any = [];
  idClientAles: any;
  numeClientAles: any;
  productModel: any = {
    id: '',
    cod: '',
    denumire: '',
    cantitate: '',
  }
  personModel: any = {
    nume: '',
    semnatura: ''
  }
  personModelList: any = [];
  proiectId: any;
  fieldArray: any = [];
  externalDataRetrievedFromServer: any = [[{text: 'Nr. Crt.', style: 'tableHeader', alignment: 'center'},{text: 'Cod', style: 'tableHeader', alignment: 'center'},{text: 'Denumire', style: 'tableHeader', alignment: 'center'}, {text: 'Cantitate', style: 'tableHeader', alignment: 'center'}]];
  externalDataRetrievedFromServerPubsh: any;
  nrCrtListaProduse: number = 0;
  openAviz: boolean = false;
  message: string | undefined;
  semnatura1: any;
  semnatura2: any;  
  semnatura3: any;
  semnatura4: any;
  semnatura5: any;
  openProces: boolean = false;
  imageSrc1 = this.appUrl + 'assets/procesverbal.png';
  numePersoana: any;
  client: any;
  constructor(public datepipe: DatePipe, public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
     config.backdrop = 'static';
     config.keyboard = false;
    }
    
  ngOnInit(): void {
    if(innerWidth < 800){
      this.view = [innerWidth / 1.3, 250];
      this.view1 = [innerWidth / 1.3, 250];
      this.view2 = [innerWidth / 1.3, 250];
      this.view3 = [innerWidth / 1.3, 250];

    }
    else{
      this.view = [innerWidth / 4, 250];
      this.view1 = [innerWidth / 4, 250];
      this.view2 = [innerWidth / 4, 250];
      this.view3 = [innerWidth / 4, 250];

    }
    this.nav.show();
    this.sessionData.current_page_title = "DASHBOARD";
  }

//#region Adauga produs
addModal(content: any) {
  this.modalService.open(content);
}

AddResource(fAddCategory: NgForm){

  this.proiectUserModel = {
    proiectId : fAddCategory.value.Proiect,
    userId : fAddCategory.value.UserName,
    dataInceput: fAddCategory.value.DataInceput,
    dataSfarsit: fAddCategory.value.DataSfarsit,
    descriere: fAddCategory.value.Descriere
  }
  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  return this.http.post(this.appUrl + 'api/ProiectUser/', this.proiectUserModel, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );

}
//#endregion

//#region Adauga aviz
avizModal(content: any) {
  this.openAviz = true;
  
  this.sessionData.users.forEach((element: any) => {
    if(element.rol.tip == 'Client'){
      this.listaClienti.push(element);
    }
  });
  
}
changeUser(){
  this.listaProiecte = [];
  this.listaClienti.forEach((element: { nume: string; prenume: string; id: any; }) => {
    if(element.nume + " " + element.prenume == this.numeClientAles){
      this.idClientAles = element.id;
      this.client = element;
      console.log(element);
    }
  }); 
  this.sessionData.proiecte.forEach((element: any) => {
    if(element.userId == this.idClientAles){
      this.listaProiecte.push(element);
    }
  });
}
addProduct(){
  this.sessionData.getGestiunePersonalaById.forEach((element: any) => {
    if(element.gestiunePrincipala.codIntern == this.codProduct){
      this.productModel = {
        id: element.id,
        cod: element.gestiunePrincipala.codIntern,
        denumire: element.gestiunePrincipala.denumire,
        cantitate: element.cantitate,
      }
       this.fieldArray.push(this.productModel);
    }
  });
}
deleteFieldValue(index: number) {
  this.fieldArray.splice(index, 1);
}
AvizResource(fAvizCategory: NgForm){

  this.fieldArray.forEach((element: any) => {
    this.nrCrtListaProduse = this.nrCrtListaProduse + 1;
    this.externalDataRetrievedFromServerPubsh = [{style:'tableBody', colSpan: 1, alignment: 'center', text: this.nrCrtListaProduse}, {style:'tableBody', colSpan: 1, alignment: 'center', text: element.cod}, {style:'tableBody', colSpan: 1, alignment: 'center', text: element.denumire}, {style:'tableBody', colSpan: 1, alignment: 'center', text: element.cantitate}]
    this.externalDataRetrievedFromServer.push(this.externalDataRetrievedFromServerPubsh);
  });

  // return this.http.post(this.appUrl + 'api/ProiectUser/', this.proiectUserModel).subscribe(
  //   (response) => {    
  //     this.message = "Asignarea a fost adaugată!"
  //   },
  //   (error) => { 
  //     console.error('error caught in component')
  //     this.message = "Eroare"
  //   }
  // );
  var pdf: any = {
    pageMargins: [40, 30, 40, 120],
    footer: [
      this.getSemnatura()
    ],
    content: [
      {
        columns: [
          this.getFurnizor(),
          this.getTitlu(),
          this.getCumparator()
        ],
        
      },
      {
        style: 'tableExample',
        table: {
          headerRows: 1,
          widths: ['5%', '30%', '30%', '10%'],
          body: this.externalDataRetrievedFromServer,

        },
        layout: 'lightHorizontalLines',
      },
      
    ],
    styles: {
      subheader: {
        fontSize: 10,
        margin: [0, 0, 0, 0],
        bold: true,
        alignment: 'center',
      },
      subheader1: {
        fontSize: 10,
        margin: [0, 0, 0, 9],
        bold: true,
        alignment: 'center',
      },
      tableExample: {
        margin: [2, 5, 2, 15]
      },
      tableHeader: {
        bold: true,
        fontSize: 8,
        color: 'black'
      },
      tableBody: {
        fontSize: 7
      },
      footer: {
        fontSize: 8,
        bold: true,
        margin: [15, 20, 15, 10]
      },
      tableBody1: {
        fontSize: 7,
        bold: true
      },
      tableBody2: {
        fontSize: 11,
        bold: true
      },
      header: {
        fontSize: 15,
        bold: true,
        alignment: 'center',
        margin: [0, 5, 0, 5]
      },
      titluDescriere: {
        fontSize: 15,
        bold: true,
      },
      textDescriere: {
        fontSize: 12,
        bold: true,
      },
      textEchipament: {
        fontSize: 9,
        bold: true,
      }
    },
  }

  pdfMake.createPdf(pdf).open();

}
//#endregion

//#region Adauga proces verbal

procesModal(content: any) {
  this.openProces = true;
  this.sessionData.users.forEach((element: any) => {
    if(element.rol.tip == 'Client'){
      this.listaClienti.push(element);
    }
  });
}
ProcesResource(fProcesCategory: NgForm){

  this.fieldArray.forEach((element: any) => {
    this.nrCrtListaProduse = this.nrCrtListaProduse + 1;
    this.externalDataRetrievedFromServerPubsh = [{style:'tableBody', colSpan: 1, alignment: 'center', text: this.nrCrtListaProduse}, {style:'tableBody', colSpan: 1, alignment: 'center', text: element.cod}, {style:'tableBody', colSpan: 1, alignment: 'center', text: element.denumire}, {style:'tableBody', colSpan: 1, alignment: 'center', text: element.cantitate}]
    this.externalDataRetrievedFromServer.push(this.externalDataRetrievedFromServerPubsh);
  });


  // return this.http.post(this.appUrl + 'api/ProiectUser/', this.proiectUserModel).subscribe(
  //   (response) => {    
  //     this.message = "Asignarea a fost adaugată!"
  //   },
  //   (error) => { 
  //     console.error('error caught in component')
  //     this.message = "Eroare"
  //   }
  // );
  var pdf: any = {
    pageMargins: [40, 120, 40, 120],
    footer: function(currentPage: any, pageCount: any) {
      if (currentPage == pageCount){
        return {
        style: 'tableExample', 
        table: { 
          body: [
            [{style:'tableBody', colSpan: 1, alignment: 'left', text: 'Executant', border: [false, false, false, false]},
            {style:'tableBody', colSpan: 1, alignment: 'right', text: 'Beneficiar ', border: [false, false, false, false]}],
            [{image: 'image2', width: 40, height: 40,  alignment: 'left', border: [false, false, false, false]},
            {image: 'image3', width: 40, height: 40,  alignment: 'right', border: [false, false, false, false]}]
          ], 
          widths: ['50%', '50%']}, 
          margin: [50, 20, 50, 0],
        layout: 'lightHorizontalLines'
        }
      }
      else{
        return null
      }
    },
    header: function(currentPage: any, pageCount: any) {
      if(currentPage == 1){
        return {
          alignment: 'center',
          margin: [50, 20, 50, 0],
          columns: [
            {
                image: 'image1',  
                width: 500,
                alignment: 'center'
            }
          ]
       }
      }
      else{
        return null
      }
      
   },
    content: [
      this.getTitleProces(),
      this.getSection1(fProcesCategory.value.DenumireLucrare, fProcesCategory.value.NumarulContractului),
      this.getSection2(this.numeClientAles, this.client.client.adresaBeneficiar, fProcesCategory.value.NumarRegistruComertului, fProcesCategory.value.SerieBen, fProcesCategory.value.NumarBen, this.client.client.cui, fProcesCategory.value.CNP, fProcesCategory.value.NumeBen, fProcesCategory.value.FunctieExpeditor),
      this.getSection3(),
      this.getSection4(this.numeClientAles),
      this.getSection5(),
      this.getSection6(this.client.client.adresaBeneficiar),
    ],
    styles: {
      subheader: {
        fontSize: 10,
        margin: [0, 0, 0, 0],
        bold: true,
        alignment: 'center',
      },
      subheader1: {
        fontSize: 10,
        margin: [0, 0, 0, 9],
        bold: true,
        alignment: 'center',
      },
      tableExample: {
        margin: [2, 5, 2, 15]
      },
      tableHeader: {
        bold: true,
        fontSize: 11,
        color: 'black'
      },
      tableBody: {
        fontSize: 10
      },
      footer: {
        fontSize: 8,
        bold: true,
        margin: [15, 20, 15, 10]
      },
      tableBody1: {
        fontSize: 7,
        bold: true
      },
      tableBody2: {
        fontSize: 11,
        bold: true
      },
      header: {
        fontSize: 15,
        bold: true,
        alignment: 'center',
        margin: [0, 5, 0, 5]
      },
      titluDescriere: {
        fontSize: 15,
        bold: true,
      },
      textDescriere: {
        fontSize: 12,
        bold: true,
      },
      textEchipament: {
        fontSize: 9,
        bold: true,
      }
    },
    images: {
      image1: this.imageSrc1,
      image2: this.semnatura3,
      image3: this.semnatura4
    }
  }

  pdfMake.createPdf(pdf).open();

}

addPerson(){
  this.personModel = {
    nume: this.numePersoana,
    semnatura: this.semnatura5
  }
  this.personModelList.push(this.personModel);
  this.signaturePad5?.clear();
}
//#endregion

//#region Program Useri
  @ViewChild('scheduleObj') public scheduleObj: ScheduleComponent | any;
  @ViewChild('scheduleObj') public scheduleObj1: ScheduleComponent | any;

  public selectedDate: Date = new Date();
  public currentView: View = 'TimelineMonth';
  public group: GroupModel = {
    enableCompactView: false,
    resources: ['Resources']
  };
  public resourceDataSource: Record<string, any>[] = this.generateResourceData(1, 300, 'Resource');
  public allowMultiple = true;
  public virtualScroll = true;
  public eventSettings: EventSettingsModel = {
    dataSource: this.generateStaticEvents(new Date(2021, 4, 1), 300, 12)
  };

  public generateStaticEvents(start: Date, resCount: number, overlapCount: number): Record<string, any>[] {
    const data: Record<string, any>[] = [];
    let id = 1;
    for (let i = 0; i < resCount; i++) {
      const randomCollection: number[] = [];
      let random = 0;
      for (let j = 0; j < overlapCount; j++) {
        random = Math.floor(Math.random() * (30));
        random = (random === 0) ? 1 : random;
        if (randomCollection.indexOf(random) !== -1 || randomCollection.indexOf(random + 2) !== -1 ||
          randomCollection.indexOf(random - 2) !== -1) {
          random += (Math.max.apply(null, randomCollection) + 10);
        }
        for (let k = 1; k <= 2; k++) {
          randomCollection.push(random + k);
        }
        let startDate: Date = new Date(start.getFullYear(), start.getMonth(), random);
        startDate = new Date(startDate.getTime() + (((random % 10) * 10) * (1000 * 60)));
        const endDate: Date = new Date(startDate.getTime() + ((1440 + 30) * (1000 * 60)));
        data.push({
          Id: id,
          Subject: 'Event #' + id,
          StartTime: startDate,
          EndTime: endDate,
          IsAllDay: (id % 10) ? false : true,
          ResourceId: i + 1
        });
        id++;
      }
    }
    return data;
  }

  public generateResourceData(startId: number, endId: number, text: string): Record<string, any>[] {
    const data: Record<string, any>[] = [];
    const colors: string[] = [
      '#ff8787', '#9775fa', '#748ffc', '#3bc9db', '#69db7c', '#fdd835', '#748ffc',
      '#9775fa', '#df5286', '#7fa900', '#fec200', '#5978ee', '#00bdae', '#ea80fc'
    ];
    for (let a: number = startId; a <= endId; a++) {
      const n: number = Math.floor(Math.random() * colors.length);
      data.push({ Id: a, Text: text + ' ' + a, Color: colors[n] });
    }
    return data;
  }

  public onActionComplete(args: ActionEventArgs) {
    if(args.requestType === 'eventChanged') {
      var x : any = args.data;
      this.sessionData.proiecte.forEach((element: { denumireProiect: any; id: any; }) => {
        if(element.denumireProiect == x[0].Subject){
          this.proiectId = element.id;
        }
      });
      this.proiectUserModelUpdate = {
        id: x[0].Id,
        proiectId : this.proiectId,
        userId : x[0].ResourceId,
        dataInceput: x[0].StartTime,
        dataSfarsit: x[0].EndTime,
        descriere: x[0].Description
      }
      
      console.log(x[0]);
      var header = {
        headers: new HttpHeaders()
          .set('Authorization',  `Bearer ${this.authService.getToken()}`)
      }
      return this.http.put(this.appUrl + 'api/ProiectUser/' + this.proiectUserModelUpdate.id, this.proiectUserModelUpdate, header).subscribe();
    }
    else if(args.requestType === 'eventRemoved'){
      var x : any = args.data;
      var header = {
        headers: new HttpHeaders()
          .set('Authorization',  `Bearer ${this.authService.getToken()}`)
      }
      return this.http.delete(this.appUrl + 'api/ProiectUser/' + x[0].Id, header).subscribe();
    }
    else{
      return;
    }
  }

  //#endregion

//#region PDF Aviz
getFurnizor(){
  var table: any = [];
  var line1 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: 'Furnizor: ', border: [false, false, false, false]},
              {style:'tableBody', colSpan: 1, alignment: 'center', text: 'Green Electric City', border: [false, false, false, false]},
  ];
  table.push(line1);
  var line2 = [
    {style:'tableBody', colSpan: 1, alignment: 'left', text: 'Nr. Ord. reg. com./an: ', border: [false, false, false, false]},
    {style:'tableBody', colSpan: 1, alignment: 'center', text: '123235646578656543', border: [false, false, false, false]}
  ];
  table.push(line2);
  var line3 = [
    {style:'tableBody', colSpan: 1, alignment: 'left', text: 'C.I.F.: ', border: [false, false, false, false]},
    {style:'tableBody', colSpan: 1, alignment: 'center', text: 'RO12341231', border: [false, false, false, false]}
  ];
  table.push(line3);
  var line4 = [
    {style:'tableBody', colSpan: 1, alignment: 'left', text: 'Sediul:', border: [false, false, false, false]},
    {style:'tableBody', colSpan: 1, alignment: 'center', text: 'Strada Vasile Lascar, Nr. 178', border: [false, false, false, false]}
  ];
  table.push(line4);
  var line5 = [
    {style:'tableBody', colSpan: 1, alignment: 'left', text: 'Judetul:', border: [false, false, false, false]},
    {style:'tableBody', colSpan: 1, alignment: 'center', text: 'Bucuresti', border: [false, false, false, false]}
  ];
  table.push(line5);
  var line6 = [
    {style:'tableBody', colSpan: 1, alignment: 'left', text: 'Cod IBAN:', border: [false, false, false, false]},
    {style:'tableBody', colSpan: 1, alignment: 'center', text: 'RO1B31007593840000', border: [false, false, false, false]}
  ];
  table.push(line6);
  var line7 = [
    {style:'tableBody', colSpan: 1, alignment: 'left', text: 'Banca:', border: [false, false, false, false]},
    {style:'tableBody', colSpan: 1, alignment: 'center', text: 'Banca Transilvania', border: [false, false, false, false]}
  ];
  table.push(line7);

  return { style: 'tableExample', table: { body: table, widths: ['50%', '50%']}, layout: 'lightHorizontalLines',}

}

getTitlu(){
  var subTable: any = [];
  var subLine1 = [
    {style:'tableBody', colSpan: 1, alignment: 'center', text: 'Seria: ', border: [false, false, false, false]},
    {style:'tableBody', colSpan: 1, alignment: 'center', text: '12345', border: [false, false, false, false]},
    {style:'tableBody', colSpan: 1, alignment: 'center', text: 'Nr: ', border: [false, false, false, false]},
    {style:'tableBody', colSpan: 1, alignment: 'center', text: '656879u', border: [false, false, false, false]}
  ];
  subTable.push(subLine1);
  var subLine2 = [
    {style:'tableBody', colSpan: 2, alignment: 'center', text: 'Data (ziua, luna, anul):', border: [false, false, false, false]},
    {text: '', border: [false, false, false, false]},
    {style:'tableBody', colSpan: 2, alignment: 'center', text: '12.08.2021', border: [false, false, false, false]},
    {text: '', border: [false, false, false, false]}
  ];
  subTable.push(subLine2);
  var table: any = [];
  var line1 = [{style:'tableBody2', colSpan: 1, alignment: 'center', text: 'AVIZ DE INSOTIRE A MARFII', border: [false, false, false, false]}];
  table.push(line1);
  var line2 = [{
    style: 'tableExample', table: { body: subTable, widths: ['25%', '25%', '25%', '25%'], border: [true, true, true, true]}
  }];
  table.push(line2);


  return { style: 'tableExample', table: { body: table, widths: ['100%']}}
}

getCumparator(){
  var table: any = [];
  var line1 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: 'Cumparator: ', border: [false, false, false, false]},
              {style:'tableBody', colSpan: 1, alignment: 'center', text: 'Green Electric City', border: [false, false, false, false]},
  ];
  table.push(line1);
  var line2 = [
    {style:'tableBody', colSpan: 1, alignment: 'left', text: 'Nr. Ord. reg. com./an: ', border: [false, false, false, false]},
    {style:'tableBody', colSpan: 1, alignment: 'center', text: '123235646578656543', border: [false, false, false, false]}
  ];
  table.push(line2);
  var line3 = [
    {style:'tableBody', colSpan: 1, alignment: 'left', text: 'C.I.F.: ', border: [false, false, false, false]},
    {style:'tableBody', colSpan: 1, alignment: 'center', text: 'RO12341231', border: [false, false, false, false]}
  ];
  table.push(line3);
  var line4 = [
    {style:'tableBody', colSpan: 1, alignment: 'left', text: 'Sediul:', border: [false, false, false, false]},
    {style:'tableBody', colSpan: 1, alignment: 'center', text: 'Strada Vasile Lascar, Nr. 178', border: [false, false, false, false]}
  ];
  table.push(line4);
  var line5 = [
    {style:'tableBody', colSpan: 1, alignment: 'left', text: 'Judetul:', border: [false, false, false, false]},
    {style:'tableBody', colSpan: 1, alignment: 'center', text: 'Bucuresti', border: [false, false, false, false]}
  ];
  table.push(line5);
  var line6 = [
    {style:'tableBody', colSpan: 1, alignment: 'left', text: 'Cod IBAN:', border: [false, false, false, false]},
    {style:'tableBody', colSpan: 1, alignment: 'center', text: 'RO1B31007593840000', border: [false, false, false, false]}
  ];
  table.push(line6);
  var line7 = [
    {style:'tableBody', colSpan: 1, alignment: 'left', text: 'Banca:', border: [false, false, false, false]},
    {style:'tableBody', colSpan: 1, alignment: 'center', text: 'Banca Transilvania', border: [false, false, false, false]}
  ];
  table.push(line7);

  return { style: 'tableExample', table: { body: table, widths: ['50%', '50%']}, layout: 'lightHorizontalLines',}

}

getSemnatura(){
  
  var subTable1: any = [];
  var subLine1_1 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: 'Semnatura expeditorului', border: [false, false, false, false]}];
  var subLine1_2 = [{image: this.semnatura1, width: 40, height: 40,  alignment: 'center', border: [false, false, false, false]}];
  subTable1.push(subLine1_1);
  subTable1.push(subLine1_2);

  var subTable2: any = [];
  var subLine2_2 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: 'Numele expeditorului: ', border: [false, false, false, false]},
                    {style:'tableBody', colSpan: 1, alignment: 'left', text: 'OK', border: [false, false, false, false]}];
  var subLine2_3 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: 'B.I/C.I. seria ' + 'XZ', border: [false, false, false, false]},
                    {style:'tableBody', colSpan: 1, alignment: 'left', text: 'nr. ' + '123543', border: [false, false, false, false]}];
  var subLine2_5 = [{style:'tableBody', colSpan: 2, alignment: 'left', text: 'Expedierea s-a facut in prezenta noastra la', border: [false, false, false, false]},
                    {text: '', border: [false, false, false, false]}];
  var subLine2_6= [{style:'tableBody', colSpan: 1, alignment: 'left', text: 'Data de ' + '24.08.1998', border: [false, false, false, false]},
                    {style:'tableBody', colSpan: 1, alignment: 'left', text: 'ora ' + '12:03', border: [false, false, false, false]}];
  subTable2.push(subLine2_2);
  subTable2.push(subLine2_3);
  subTable2.push(subLine2_5);
  subTable2.push(subLine2_6);

  var subTable3: any = [];
  var subLine3_2 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: 'Numele beneficiarului: ', border: [false, false, false, false]},
                    {style:'tableBody', colSpan: 1, alignment: 'left', text: 'OK', border: [false, false, false, false]}];
  var subLine3_3 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: 'B.I/C.I. seria ' + 'XZ', border: [false, false, false, false]},
                    {style:'tableBody', colSpan: 1, alignment: 'left', text: 'nr. ' + '123543', border: [false, false, false, false]}];
  var subLine3_5 = [{style:'tableBody', colSpan: 2, alignment: 'left', text: 'Expedierea s-a facut in prezenta noastra la', border: [false, false, false, false]},
                    {text: '', border: [false, false, false, false]}];
  var subLine3_6= [{style:'tableBody', colSpan: 1, alignment: 'left', text: 'Data de ' + '24.08.1998', border: [false, false, false, false]},
                    {style:'tableBody', colSpan: 1, alignment: 'left', text: 'ora ' + '12:03', border: [false, false, false, false]}];

  subTable3.push(subLine3_2);
  subTable3.push(subLine3_3);
  subTable3.push(subLine3_5);
  subTable3.push(subLine3_6);

  var subTable4: any = [];
  var subLine4_1 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: 'Semnatura beneficiarului', border: [false, false, false, false]}];
  var subLine4_2 = [{image: this.semnatura2, width: 40, height: 40,  alignment: 'center', border: [false, false, false, false]}];
  subTable4.push(subLine4_1);
  subTable4.push(subLine4_2);

  var table: any = [];
  var line = [{style: 'tableExample', table: { body: subTable2, widths: ['50%', '50%'], border: [true, true, true, true]}},
              {style: 'tableExample', table: { body: subTable1, widths: ['100%'], border: [true, true, true, true]}},
              {style: 'tableExample', table: { body: subTable3, widths: ['50%', '50%'], border: [true, true, true, true]}},
              {style: 'tableExample', table: { body: subTable4, widths: ['100%'], border: [true, true, true, true]}}];
  table.push(line);

  return { style: 'tableExample', table: { body: table, widths: ['38%', '12%', '38%', '12%']}}

}
//#endregion

//#region Semnatura
  // @ViewChild(SignaturePad) signaturePad: SignaturePad | undefined;
  // @ViewChild(SignaturePad) signaturePad1: SignaturePad | undefined;
  @ViewChild('sigpad1') signaturePad: SignaturePad | undefined;
  @ViewChild('sigpad2') signaturePad2: SignaturePad | undefined;
  @ViewChild('sigpad3') signaturePad3: SignaturePad | undefined;
  @ViewChild('sigpad4') signaturePad4: SignaturePad | undefined;
  @ViewChild('sigpad5') signaturePad5: SignaturePad | undefined;


  private signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
    'minWidth': 5,
    'canvasWidth': 500,
    'canvasHeight': 300
  };
  private signaturePadOptions1: Object = { // passed through to szimek/signature_pad constructor
    'minWidth': 5,
    'canvasWidth': 500,
    'canvasHeight': 300
  };

  // ngAfterViewInit() {
  //   // this.signaturePad is now available
  //   this.signaturePad?.set('minWidth', 5); // set szimek/signature_pad options at runtime
  //   this.signaturePad?.clear(); // invoke functions from szimek/signature_pad API
  // }

  drawClear(){
    this.signaturePad?.clear();
  }
  drawComplete(){
    const base64 = this.signaturePad?.toDataURL('image/png');
    this.semnatura1 = base64;
  }
  drawClear1(){
    this.signaturePad2?.clear();
  }
  drawComplete1(){
    const base64 = this.signaturePad2?.toDataURL('image/png');
    this.semnatura2 = base64;
  }
  drawComplete2(){
    const base64 = this.signaturePad3?.toDataURL('image/png');
    this.semnatura3 = base64;
  }
  drawComplete3(){
    const base64 = this.signaturePad4?.toDataURL('image/png');
    this.semnatura4 = base64;
  }
  drawComplete5(){
    const base64 = this.signaturePad5?.toDataURL('image/png');
    this.semnatura5 = base64;
  }
  drawStart() {
    // will be notified of szimek/signature_pad's onBegin event
  }
  drawClear3(){
    this.signaturePad3?.clear();
  }
  drawClear4(){
    this.signaturePad4?.clear();
  }
  drawClear5(){
    this.signaturePad5?.clear();
  }
  //#endregion

//#region PDF Proces
getTitleProces(){
  var table: any = [];
  var line1 = [{style:'tableHeader', colSpan: 1, alignment: 'center', text: 'PROCES-VERBAL DE RECEPŢIE A LUCRARILOR', border: [false, false, false, false]}];
  table.push(line1);
  var line2 = [
    {style:'tableHeader', colSpan: 1, alignment: 'center', text: 'Nr. ' + '12022', border: [false, false, false, false]}];
  table.push(line2);

  return { style: 'tableExample', table: { body: table, widths: ['100%']}, layout: 'lightHorizontalLines',}
}
getSection1(denumireLucrare: any, numarContract: any){
  var table: any = [];
  var line1 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: 'Privind lucrarea ' + denumireLucrare + ' executata in cadrul contractului de prestari servicii nr. ' + numarContract, border: [false, false, false, false]}];
  table.push(line1);

  return { style: 'tableExample', table: { body: table, widths: ['100%']}, layout: 'lightHorizontalLines',}
}
getSection2(numeClient: any, domiciliuClient: any, numarRegistruComertului: any, serie: any, numarSerie: any, cui: any, cnp: any, numeReprezentant: any, functie: any){
  var table: any = [];
  var line1 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: 'Încheiat între: ', border: [false, false, false, false]}];
  table.push(line1);
  var line2 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: numeClient + ' persoană juridică / persoana fizica română cu sediul social / domiciliul in ' + domiciliuClient + ' înregistrată la Registrul Comerțului cu nr. ' + numarRegistruComertului + ' legitimat cu CI seria ' + serie + ' nr. ' + numarSerie + ' cod unic de înregistrare ' + cui + ' CNP ' + cnp + ' reprezentata de ' + numeReprezentant + ' cu functia de ' + functie + ' in calitate de Beneficiar, ' , border: [false, false, false, false]}];
  table.push(line2);
  var line3 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: 'si', border: [false, false, false, false]}];
  table.push(line3);
  var line4 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: 'GREEN ELECTRIC CITY  SRL persoană juridică română cu sediul social in Bucuresti, Str. Vasile Lascar, nr.178, et.5, ap. 10, Sector 2 , înregistrată la Registrul Comerțului cu nr. J40/2817/2009, cod unic de înregistrare RO 25220494, reprezentata  de Laurentiu-Ionel Cojocaru, cu functia de Administrator, in calitate de Exécutant,', border: [false, false, false, false]}];
  table.push(line4);
  var line5 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: 'au convenit sa incheie prezentul contract cu respectarea urmatoarelor clauze : ', border: [false, false, false, false]}];
  table.push(line5);
  return { style: 'tableExample', table: { body: table, widths: ['100%']}, layout: 'lightHorizontalLines'}
}
getSection3(){
  var table: any = [];

  var line1 = [{style:'tableBody', colSpan: 2, alignment: 'left', text: '1.	Comisia de recepţie din data de ' + this.datepipe.transform(this.sessionData.productDetails.data, 'dd-MM-yyyy') + ' fiind formată din: ', border: [false, false, false, false]},
              {text: '', border: [false, false, false, false]}];
  table.push(line1);
  this.personModelList.forEach((element: any) => {
    var line2 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: element.nume, border: [false, false, false, false]},
                {image: element.semnatura, width: 40, height: 40,  alignment: 'left', border: [false, false, false, false]}];
    table.push(line2);
  });
  // var line2 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: 'Fratila Costin', border: [false, false, false, false]}];
  // table.push(line2);
  // var line2 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: 'Fratila Costin', border: [false, false, false, false]}];
  // table.push(line2);

  return { style: 'tableExample', table: { body: table, widths: ['50%', '50%']}, layout: 'lightHorizontalLines',}

}
getSection4(numeClientAles: any){
  var table: any = [];

  var line1 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: '2.	La recepţia serviciilor a participat din partea Prestatorului ' + this.sessionData.user.Nume + " " + this.sessionData.user.Prenume + ' în calitate de reprezentant legal al GREEN ELECTRIC CITY  si din partea Beneficiarului, ' + numeClientAles, border: [false, false, false, false]}];
  table.push(line1);

  return { style: 'tableExample', table: { body: table, widths: ['100%']}, layout: 'lightHorizontalLines'}
}
getSection5(){
  var table: any = [];

  var line1 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: '3. Se constată şi consemnează urmatoarele: ', border: [false, false, false, false]}];
  table.push(line1);
  var line2 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: '-	serviciile care au făcut obiectul contractului au fost prestate de Prestator cu respectarea cerinţelor, exigenţelor, cantitătilor şi în graficul de timp, prevăzute în contractul de servicii.', border: [false, false, false, false]}];
  table.push(line2);
  var line3 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: '-	prestatorul şi-a indeplinit toate obligaţiile asumate prin contractul încheiat între părţi.', border: [false, false, false, false]}];
  table.push(line3);
  var line4 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: '-	sistemul functioneaza in parametrii tehnici normali.', border: [false, false, false, false]}];
  table.push(line4);

  return { style: 'tableExample', table: { body: table, widths: ['100%']}, layout: 'lightHorizontalLines'}
}
getSection6(adresa: any){
  var table: any = [];

  var line1 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: '4.	Prezentul proces-verbal, conţinând 1 fila a fost încheiat astăzi ' + this.datepipe.transform(this.sessionData.productDetails.data, 'dd-MM-yyyy') + ' la adresa ' + adresa + ' în 2  exemplare, din care unul pentru Beneficiar şi unul pentru Prestator.', border: [false, false, false, false]}];
  table.push(line1);

  return { style: 'tableExample', table: { body: table, widths: ['100%']}, layout: 'lightHorizontalLines'}
}
getSection7(){
  var table: any = [];

  var line1 = [{style:'tableBody', colSpan: 1, alignment: 'left', text: 'Executant', border: [false, false, false, false]},
              {style:'tableBody', colSpan: 1, alignment: 'right', text: 'Beneficiar ', border: [false, false, false, false]}];
  table.push(line1);
  var line2 = [{image: this.semnatura3, width: 40, height: 40,  alignment: 'left', border: [false, false, false, false]},
                {image: this.semnatura4, width: 40, height: 40,  alignment: 'right', border: [false, false, false, false]}];
  table.push(line2);
  
  return { style: 'tableExample', table: { body: table, widths: ['50%', '50%']}, layout: 'lightHorizontalLines'}
}
//#endregion
}