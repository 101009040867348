import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-rezervare-proiecte',
  templateUrl: './rezervare-proiecte.component.html',
  styleUrls: ['./rezervare-proiecte.component.css']
})
export class RezervareProiecteComponent implements OnInit {

  //#region Declarari
  projectOption : any = "Caută după Proiect";
  userId: number | undefined;
  appUrl: string = environment.appUrl;
  selectedfile: File | undefined;
  message: string | undefined;
  denumire: any;
  gPrincipala : any;
  p: number = 1;
  gRezervareProiectModel: any = {
    id: '',
    cantitate: '',
    data: '',
    gestiunePrincipala: '',
    proiect: ''
  }
  returGPrincipalaModel: any = {
    gestiunePrincipalaId: '',
    gestiuneRezervareId: '',
    numeUser: '',
    cantitate: ''
  };
  addGTransferModel: any = {
    gestiuneRezervareProiectId: '',
    gestiunePrincipalaId: '',
    cantitate: '',
    userId: '',
    numeUser: ''
  };
  excelProiectModel: any = {
    proiectId: '',
    dataInceput: '',
    dataSfarsit: ''
   }
  fileName= 'ExcelSheet.xlsx';
  isChecked: any = false;
  proiectId: any;
  buffer: any;
  userName : any;
//#endregion

  constructor(public datepipe: DatePipe, public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {

    this.sessionData.current_page_title = "GESTIUNE REZERVARE PROIECT"
    this.nav.show();
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    this.http.get<any>(this.appUrl + 'api/GestiuneRezervareProiect/getGestiuneRezervarePeProiect', header).subscribe((data) => {
      this.sessionData.gestiuneRezervareProiect = data.sort((a: { denumire: number; }, b: { denumire: number; }) => (a.denumire < b.denumire ? -1 : 1));
    });
  }

  //functie pentru fisierul incarcat
  onFS(event: any){
    if(<File>event.target.files[0] != null){
      this.selectedfile = <File>event.target.files[0];
    }
  }


//#region Retur Produs
returModal(content: any, gp: any) {
  this.modalService.open(content);
  this.gRezervareProiectModel.id = gp.id;
  this.gRezervareProiectModel.cantitate = gp.cantitate;
  this.gRezervareProiectModel.data = gp.data;
  this.gRezervareProiectModel.gestiunePrincipala = gp.gestiunePrincipala;
  this.gRezervareProiectModel.proiect = gp.proiect;
}
DeleteResource(fReturProdus: any){

    if(fReturProdus.value.gestiunePrincipala == true && fReturProdus.value.rezervareProiect == true){
      this.message = "Alege o singura optiune!"
    }
    this.returGPrincipalaModel = {
      gestiunePrincipalaId: this.gRezervareProiectModel.gestiunePrincipala.id,
      gestiuneRezervareId: this.gRezervareProiectModel.id,
      numeUser: this.sessionData.user.nume + " " + this.sessionData.user.prenume,
      cantitate: this.gRezervareProiectModel.cantitate
    }

    if(fReturProdus.value.gestiunePrincipala == true){
      var header = {
        headers: new HttpHeaders()
          .set('Authorization',  `Bearer ${this.authService.getToken()}`)
      }
      this.http.post(this.appUrl + 'api/GestiuneRezervareProiect/deleteToGestiunePrincipala/' + this.gRezervareProiectModel.id,  this.returGPrincipalaModel, header).subscribe(
        (response) => {    
          window.location.reload();
        },
        (error) => { 
          console.error('error caught in component')
          this.message = "Eroare"
        }
      );
    }
    else if(fReturProdus.value.rezervareProiect == true){
      var header = {
        headers: new HttpHeaders()
          .set('Authorization',  `Bearer ${this.authService.getToken()}`)
      }
      this.http.post(this.appUrl + 'api/GestiuneRezervareProiect/deleteToAprovizionareRezervari/',  this.returGPrincipalaModel, header).subscribe(
        (response) => {    
          window.location.reload();
        },
        (error) => { 
          console.error('error caught in component')
          this.message = "Eroare"
        }
      );
    }
    
}
//#endregion

//#region Transfera in Gestiune Transfer

addToGTransferModal(content: any, gp: any) {
  this.modalService.open(content);
  this.gRezervareProiectModel.id = gp.id;
  this.gRezervareProiectModel.cantitate = gp.cantitate;
  this.gRezervareProiectModel.data = gp.data;
  this.gRezervareProiectModel.gestiunePrincipala = gp.gestiunePrincipala;
  this.gRezervareProiectModel.proiect = gp.proiect;
}

AddGTransferResource(fAddGTransfer: NgForm){

  if(fAddGTransfer.value.Cantitate < 0){
    this.message = "Valoarea introdusa nu poate sa fie mai mica decat 0";
    return;
  }
  else if(fAddGTransfer.value.Cantitate > this.gRezervareProiectModel.cantitate){
    this.message = "Valoarea introdusa este prea mare";
    return;
  }
  else{
    this.addGTransferModel = {
      gestiuneRezervareProiectId: this.gRezervareProiectModel.id,
      gestiunePrincipalaId: this.gRezervareProiectModel.gestiunePrincipala.id,
      cantitate: fAddGTransfer.value.Cantitate,
      userId: fAddGTransfer.value.UserName,
      numeUser: this.sessionData.user.nume + " " + this.sessionData.user.prenume
    }
    var header = {
      headers: new HttpHeaders()
        .set('Authorization',  `Bearer ${this.authService.getToken()}`)
    }
    return this.http.post(this.appUrl + 'api/GestiuneRezervareProiect/addGestiuneTransfer', this.addGTransferModel, header).subscribe(
      (response) => {    
        window.location.reload();
      },
      (error) => { 
        console.error('error caught in component')
        this.message = "Eroare"
      }
    );
}


}

//#endregion


Search(){
  if(this.denumire != ""){
    this.sessionData.gestiunePrincipalaInventory = this.sessionData.gestiunePrincipalaInventory.filter((res: { denumire: string; }) => {
      return res.denumire.toLocaleLowerCase().match(this.denumire.toLocaleLowerCase());
    });
  }else if(this.denumire == ""){
    this.authService.getGestiunePrincipala(this.authService.getToken());
  }

}

//generare excel
exportExcel() : void {

  let url = this.appUrl + 'api/GestiunePrincipala/RaportGestiunePrincipala';
  window.open(url);
}

//enable proiecte
checkValue(event: any){
}

selectChangeHandler (event: any) {
  //update the ui
  this.projectOption = event.target.value;
  if(this.projectOption == ''){
    this.projectOption = "Caută după Proiect";
  }
}


//#region excel proiect
exportExcelProiect(content: any) : void {
  this.modalService.open(content);

}

ExportExcelProiectResource(fexportExcelProiect: any){
  if(fexportExcelProiect.value.gestiunePrincipala == true && fexportExcelProiect.value.rezervareProiect == true){
    this.message = "Alege o singura optiune!"
  }

  else if(fexportExcelProiect.value.gestiunePrincipala == true){
    this.excelProiectModel = {
      proiectId: 0,
      dataInceput: this.adjustDateForTimeOffset(fexportExcelProiect.value.DataInceput),
      dataSfarsit: this.adjustDateForTimeOffset(fexportExcelProiect.value.DataSfarsit)
    }

  }
  else if(fexportExcelProiect.value.rezervareProiect == true){

    this.sessionData.gestiuneProiect.forEach((element: { proiect: { denumireProiect: any; id: any; }; }) => {
      if(element.proiect.denumireProiect == fexportExcelProiect.value.Proiect){
        this.proiectId = element.proiect.id;
      }
    });
    this.excelProiectModel = {
      proiectId: this.proiectId,
      dataInceput: this.adjustDateForTimeOffset(fexportExcelProiect.value.DataInceput),
      dataSfarsit: this.adjustDateForTimeOffset(fexportExcelProiect.value.DataSfarsit)
    }
  }
  console.log(this.excelProiectModel);
  this.http.post(this.appUrl + 'api/GestiuneRezervareProiect/raportGestiuneRezervareProiectDupaProiect', this.excelProiectModel, { responseType: 'blob'} ).subscribe((data) => {
    const blob = new Blob([data], { type: 'application/octet-stream'});
    const url= window.URL.createObjectURL(blob);
    window.open(url);
  });

}
//#endregion
refresh(){
  if(this.message == null){
    this.modalService.dismissAll();
  }
  else{
    window.location.reload();
  }
}

adjustDateForTimeOffset(dateToAdjust: any) {
  var offsetMs = dateToAdjust.getTimezoneOffset() * 60000;
  return new Date(dateToAdjust.getTime() - offsetMs);
  }

//#region Adauga Csv
addCsvModal(content: any) {
  this.modalService.open(content);
}

AddCsvResource(fAddCsvCategory: NgForm){

  let myDate = new Date(Date.now());
  fAddCsvCategory.value.File = "file";
  fAddCsvCategory.value.Files = this.selectedfile;
  const formData = new FormData();
  this.buffer = true;

  formData.append("CsvFile",  fAddCsvCategory.value.Files);

  return this.http.post(this.appUrl + 'api/GestiunePrincipala/uploadCsv',  formData).subscribe(
  (response) => {    
    this.buffer = false
    this.message = "Fisierul a fost incarcat!"
  },
  (error) => { 
    this.buffer = false;
    this.message = "Eroare"
  }
);

}
//#endregion


}
