import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ofertare-gestiune-oferte',
  templateUrl: './ofertare-gestiune-oferte.component.html',
  styleUrls: ['./ofertare-gestiune-oferte.component.css']
})
export class OfertareGestiuneOferteComponent implements OnInit {

  userId: number | undefined;
  appUrl: string = environment.appUrl;
  selectedfile: File | undefined;
  message: string | undefined;
  numarOferta: any;
  listaClienti: any = [];
  numeClientAles: any;
  idClientAles: any;
  listaProiecte: any = [];
  numeProiectAles: any;
  ofertaId: any;
  denumireOferta: any;
  denumire: any;


  constructor(public datepipe: DatePipe, public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
    localStorage.removeItem('NumarOferta');
    localStorage.removeItem('NumeClient');
    localStorage.removeItem('NumeProiect');
    localStorage.removeItem('DenumireOferta');
    this.sessionData.current_page_title = "BAZA DE DATE - OFERTE"
    
  }

//#region Adauga oferta
addModal(content: any) {
  this.listaClienti = [];
  if(this.sessionData.listaOferte.length != 0){
    this.numarOferta = this.sessionData.listaOferte[this.sessionData.listaOferte.length - 1].id + 1 + new Date().getFullYear().toString() + '/0';
  }
  else{
    this.numarOferta = 1 + new Date().getFullYear().toString() + '/0';
  }

  this.sessionData.users.forEach((element: any) => {
    if(element.rol.tip == 'Client'){
      this.listaClienti.push(element);
    }
  });

  this.modalService.open(content);
}
changeUser(){
  
  this.listaProiecte.splice(0,this.listaProiecte.length);

  this.listaClienti.forEach((element: { nume: string; prenume: string; id: any; }) => {
    if(element.nume + " " + element.prenume == this.numeClientAles){
      this.idClientAles = element.id;
    }
  }); 
  this.sessionData.proiecte.forEach((element: any) => {
    if(element.userId == this.idClientAles){
      this.listaProiecte.push(element);
    }
  });

}
AddResource(fAddCategory: NgForm){
  if(!this.numeProiectAles){
    alert("Clientul nu are un proiect creat!")
  }
  else{
    localStorage.setItem('NumarOferta', fAddCategory.value.NumarOferta);
    localStorage.setItem('NumeClient', this.numeClientAles);
    localStorage.setItem('NumeProiect', this.numeProiectAles);
    localStorage.setItem('DenumireOferta', this.denumireOferta);
    this.router.navigate(['/ofertare-creare-oferta']);
    this.modalService.dismissAll();
  }
  
}
//#endregion

editModal(item: any){
  console.log(item);
  this.sessionData.ofertaId = item.id;
  localStorage.setItem('OfertaId',item.id);
  localStorage.setItem('NumarOferta',item.numar);
  localStorage.setItem('DenumireOferta',item.denumireOferta);
  localStorage.setItem('NumeProiect', item.proiect.denumireProiect);
  this.router.navigate(['/ofertare-editare-oferta']);
}

//#region Creaza comanda
createComandModal(content: any, category: any) {
  this.modalService.open(content);
  this.ofertaId = category.id;
}
createComand(event: any){
  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  this.http.post(this.appUrl + 'api/OfertareGestiuneOferte/addComandaById/' + this.ofertaId, '', header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
}
//#endregion

//#region Stergere Produs
deleteModal(content: any, category: any) {
  this.modalService.open(content);
  this.ofertaId = category.id;
}
delete(event: any){
  var header = {
    headers: new HttpHeaders()
      .set('Authorization',  `Bearer ${this.authService.getToken()}`)
  }
  this.http.delete(this.appUrl + 'api/OfertareGestiuneOferte/' + this.ofertaId, header).subscribe(
    (response) => {    
      window.location.reload();
    },
    (error) => { 
      console.error('error caught in component')
      this.message = "Eroare"
    }
  );
}
//#endregion
refresh(){
  if(this.message == null){
    this.modalService.dismissAll();
  }
  else{
    window.location.reload();
  }
}

Search(){
  
  if(this.denumire != ""){
    this.sessionData.listaOferte = this.sessionData.listaOferte.filter((res: any) => {
      return res.beneficiar.toLocaleLowerCase().match(this.denumire.toLocaleLowerCase()) || res.proiect.denumireProiect.toLocaleLowerCase().match(this.denumire.toLocaleLowerCase()) || res.numar.toLocaleLowerCase().match(this.denumire.toLocaleLowerCase());
    });
  }else if(this.denumire == ""){
    this.authService.getOferte();
  }

}

}
