import { AuthService } from 'src/app/services/auth.service';
import { Component, HostListener } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { async, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { animate, style, transition, trigger } from '@angular/animations';

import { Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { SessionDataService } from 'src/app/session-data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],

})

export class SidebarComponent {

  isSidebarExpanded = true;
  isNavbarExpanded = false;
  message: any;
  isHandset$: boolean;
  showSubmenu: boolean = false;
  showSubmenu1: boolean = false;
  showSubmenu2: boolean = false;
  showSubmenu3: boolean = false;

  numarOferta: any;
  listaClienti: any = [];
  numeClientAles: any;
  idClientAles: any;
  listaProiecte: any = [];
  numeProiectAles: any;
  ofertaId: any;
  denumireOferta: any;

  constructor(private modalService: NgbModal, private breakpointObserver: BreakpointObserver, private router: Router, public authService: AuthService, public sessionData: SessionDataService) {
    if(window.innerWidth >= 940){
      this.isHandset$ = false;
    }
    else{
      this.isHandset$ = true;
    }
  }



  public onOptionClick(sidenav: { toggle: () => void; }){
    if(this.breakpointObserver.isMatched(
      "(max-width: 599px)"
    )){
      sidenav.toggle();
    }
  }
  public toggleNavbar(){
    this.isNavbarExpanded = !this.isNavbarExpanded;
  }

  logOut(){
    this.authService.logout();
    window.location.reload();
  }

  //#region Adauga oferta
addModal(content: any) {
  if(this.sessionData.listaOferte.length != 0){
    this.numarOferta = this.sessionData.listaOferte[this.sessionData.listaOferte.length - 1].id + 1 + new Date().getFullYear().toString() + '/0';
  }
  else{
    this.numarOferta = 1 + new Date().getFullYear().toString() + '/0';
  }

  this.sessionData.users.forEach((element: any) => {
    if(element.rol.tip == 'Client'){
      this.listaClienti.push(element);
    }
  });

  this.modalService.open(content);
}
changeUser(){
  this.listaProiecte = [];
  this.listaClienti.forEach((element: { nume: string; prenume: string; id: any; }) => {
    if(element.nume + " " + element.prenume == this.numeClientAles){
      this.idClientAles = element.id;
    }
  }); 
  this.sessionData.proiecte.forEach((element: any) => {
    if(element.userId == this.idClientAles){
      this.listaProiecte.push(element);
    }
  });

}
AddResource(fAddCategory: NgForm){
  localStorage.setItem('NumarOferta', fAddCategory.value.NumarOferta);
  localStorage.setItem('NumeClient', this.numeClientAles);
  localStorage.setItem('NumeProiect', this.numeProiectAles);
  localStorage.setItem('DenumireOferta', this.denumireOferta);
  this.router.navigate(['/ofertare-creare-oferta']);
  this.modalService.dismissAll();
}
//#endregion

refresh(){
  if(this.message == null){
    this.modalService.dismissAll();
  }
  else{
    window.location.reload();
  }
}

}
