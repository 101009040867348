import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';
import { SessionDataService } from 'src/app/session-data.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  
  isChecked: any = false;
  model: any = {
    Nume: null,
    Prenume: null,
    Cost: 1,
    Email: null,
    Password: null,
    ConfirmPassword: null,
    Rol: "Client",
    CNP: null,
    Companie: null,
    CUI: null,
    NORC: null,
    AdresaBeneficiar: null,
    AdresaCompanie: null,
    Banca: null,
    SediuCentral: null,
    Judet: null,
    Localitate: null,
    Telefon: null,
    IBAN: null
  };
  appUrl: string = environment.appUrl;
  selectedfile: File | undefined;
  message: string | undefined;
  constructor(public router: Router, public sessionData: SessionDataService, public authService: AuthService, private http: HttpClient, config: NgbModalConfig, private modalService: NgbModal, public nav: NavbarService){}

  ngOnInit(): void {
    this.sessionData.current_page_title = "ADAUGĂ CLIENT NOU";
    this.http.get<any>(this.appUrl + 'api/Role').subscribe((data) => {
      this.sessionData.roles = data;
    });
  }
  checkValue(){

  }

  onSubmit() {
    const registerObserver = {
      next: () => {
        // this.toastr.success('Account Created');
        alert('Clientul a fost creat!');
        setTimeout(() => {
          this.router.navigate(['/register']);
        }, 1000);
      },
      error: () => {
        alert('Fail');
        // this.toastr.error('Fail');
      },
    };
    this.authService.register(this.model).subscribe(registerObserver);
  }

}
